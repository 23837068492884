import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { calendarApi } from "../__fakeApi__/calendarApi";
import toast from "react-hot-toast";
import HttpService from "../services/HttpService.js";
import UrlService from "../services/UrlService.js";

const initialState = {
  events: [],
  isModalOpen: false,
  selectedEventId: null,
  selectedEventType: null,
  selectedRange: null,
  isLoaded: false,
};

const slice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    getEvents(state, action) {
      state.events = action.payload;
      state.isLoaded = true;
    },
    createEvent(state, action) {
      state.events.push(action.payload);
    },
    selectEvent(state, action) {
      state.isModalOpen = true;
      state.selectedEventId = action.payload.id;
      state.selectedEventType = action.payload.type;
    },
    selectEventType(state, action) {
      state.selectedEventType = action.payload;
    },
    updateEvent(state, action) {
      const event = action.payload;
      event.type = "employee";
      const nextEvent = event?.next_interaction;
      if (nextEvent) {
        nextEvent.type = "employee";
        state.events.push(nextEvent);
      }
      // nextEvent && state.events.push(nextEvent);
      state.events = state.events.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }

        return _event;
      });
    },
    updateCustomerEvent(state, action) {
      const { data_current, data_next } = action.payload;
      if (data_next) {
        data_next.type = "customer";
        data_next.result = null;

        state.events.push(data_next);
      }
      // data_next && state.events.push(data_next);
      data_current.type = "customer";
      state.events = state.events.map((_event) => {
        if (_event.id === data_current.id) {
          return data_current;
        }

        return _event;
      });
    },
    deleteEvent(state, action) {
      state.events = state.events.filter(
        (event) => event.id !== action.payload
      );
    },
    selectRange(state, action) {
      const { start, end } = action.payload;

      state.isModalOpen = true;
      state.selectedRange = {
        start,
        end,
      };
    },
    selectRangeWithModal(state, action) {
      const { start, end } = action.payload;
      state.selectedRange = {
        start,
        end,
      };
    },
    openModal(state) {
      state.isModalOpen = true;
    },
    closeModal(state) {
      state.isModalOpen = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },
  },
});

export const { reducer } = slice;

// export const getEvents = () => async (dispatch) => {
//   const data = await calendarApi.getEvents();

//   dispatch(slice.actions.getEvents(data));
// };

// export const getEvents = createAsyncThunk(
//   "calendar/getEvents",
//   async function (_, { dispatch }) {
//     try {
//       const response = await HttpService.get(
//         UrlService.apiDomain() + "api/calendar"
//       );
//       if (response.status != 200) {
//         alert("Server error");
//       }
//       dispatch(slice.actions.getEvents(response.data));
//     } catch (error) {
//       console.log(error);
//     }
//   }
// );

export const getEvents = createAsyncThunk(
  "calendar/getEvents",
  async function (_, { dispatch }) {
    try {
      const response = await HttpService.get(
        UrlService.apiDomain() + "api/calendar_get_all"
      );
      if (response.status != 200) {
        alert("Server error");
      }

      const employee_interactions = Object.values(
        response.data.employee_interactions
      );
      const customer_interactions = Object.values(
        response.data.customer_interactions
      );
      customer_interactions.forEach((interaction) => {
        let endTime = new Date(interaction.end);
        interaction.color =
          interaction.result
          ? "#bdc2cb"
          : endTime.getTime() < new Date().getTime()
          ? "#ff6942"
          : interaction.interaction == 1
          ? "#eee572"
          : "#43a048";
          interaction.type = "customer";
      });

      employee_interactions.forEach((interaction) => {
        interaction.type = "employee";
        interaction.color =
          interaction.result
          ? "#bdc2cb"
          : interaction.end < new Date().getTime()
          ? "#ff6942"
          : interaction.interaction == 1
          ? "#eee572"
          : "#43a048";
      });

      const merged_interactions = customer_interactions.concat(
        employee_interactions
      );

      dispatch(slice.actions.getEvents(merged_interactions));
    } catch (error) {
      console.log(error);
    }
  }
);

export const createEvent = createAsyncThunk(
  "calendar/createEvent",
  async function (createData, { dispatch }) {
    try {
      const response = await HttpService.post(
        UrlService.apiDomain() + "api/calendar",
        {
          createData,
        }
      );
      if (response.status != 200) {
        toast.error("Něco se pokazilo!");
        alert(response.data.message);
        return;
      }
      const event = response.data;
      event.type = "employee";
      dispatch(slice.actions.createEvent(event));
      toast.success("Kalendář aktualizován!");
    } catch (error) {
      console.log(error);
    }
  }
);
export const createCustomerEvent = createAsyncThunk(
  "calendar/createCustomerEvent",
  async function (createData, { dispatch }) {
    try {
      const response = await HttpService.post(
        UrlService.apiDomain() + "api/customer_interactions/create",

        { data: createData }
      );
      if (response.status != 200) {
        toast.error("Něco se pokazilo!");
        alert(response.data.message);
        return;
      }
      if (response.data.message) {
        alert(response.data.message);
        return;
      }
      const event = response.data.data;
      event.type = "customer";

      dispatch(slice.actions.createEvent(event));
      toast.success("Kalendář aktualizován!");
    } catch (error) {
      console.log(error);
    }
  }
);

export const selectEvent =
  ({ id, type }) =>
  async (dispatch) => {
    dispatch(slice.actions.selectEvent({ id, type }));
  };
export const selectEventType = (type) => async (dispatch) => {
  dispatch(slice.actions.selectEventType(type));
};

// export const updateEvent = (eventId, update) => async (dispatch) => {
//   const data = await calendarApi.updateEvent({
//     eventId,
//     update,
//   });

//   dispatch(slice.actions.updateEvent(data));
// };
export const updateEvent = createAsyncThunk(
  "calendar/updateEvent",
  async function ({ eventId, update }, { dispatch, rejectWithValue }) {
    try {
      const response = await HttpService.post(
        UrlService.apiDomain() + "api/update-calendar",
        {
          eventId,
          update,
        }
      );
      if (response.status == 419) {
        return rejectWithValue(response.data);
      }
      if (response.status != 200) {
        toast.error(response.data.message);
        return;
      }
      const event = response.data;
      event.type = "employee";
      dispatch(slice.actions.updateEvent(event));
      toast.success("Kalendář aktualizován!");
    } catch (error) {
      console.log(error);
    }
  }
);
export const updateCustomerEvent = createAsyncThunk(
  "calendar/updateCustomerEvent",
  async function (update, { dispatch, rejectWithValue }) {
    try {
      const response = await HttpService.post(
        UrlService.apiDomain() + `api/customer_interactions/edit`,
        update
      );

      if (response.status != 200) {
        toast.error("Něco se pokazilo!");
        return;
      }
      const event = response.data;
      event.type = "customer";

      dispatch(slice.actions.updateCustomerEvent(event));
      toast.success("Kalendář aktualizován!");
    } catch (error) {
      console.log(error);
    }
  }
);
// export const deleteEvent = (eventId) => async (dispatch) => {
//   await calendarApi.deleteEvent(eventId);

//   dispatch(slice.actions.deleteEvent(eventId));
// };
export const deleteEvent = createAsyncThunk(
  "calendar/deleteEvent",
  async function (eventId, { dispatch }) {
    try {
      const response = await HttpService.delete(
        UrlService.apiDomain() + `api/calendar/${eventId}`
      );
      if (response.status != 200) {
        toast.error("Něco se pokazilo!");
        return;
      }
      dispatch(slice.actions.deleteEvent(eventId));
      toast.success("Kalendář aktualizován!");
    } catch (error) {
      console.log(error);
    }
  }
);
export const deleteCustomerEvent = createAsyncThunk(
  "calendar/deleteCustomerEvent",
  async function (eventId, { dispatch }) {
    try {
      const response = await HttpService.post(
        UrlService.apiDomain() + `api/customer_interactions/delete`,
        {
          data: {
            id: eventId,
          },
        }
      );
      if (response.status != 200) {
        toast.error("Něco se pokazilo!");
        return;
      }
      dispatch(slice.actions.deleteEvent(eventId));
      toast.success("Kalendář aktualizován!");
    } catch (error) {
      console.log(error);
    }
  }
);
export const selectRange = (start, end) => (dispatch) => {
  dispatch(slice.actions.selectRange({ start, end }));
};
export const selectRangeWithModal = (start, end) => (dispatch) => {
  dispatch(slice.actions.selectRangeWithModal({ start, end }));
};

export const openModal = () => (dispatch) => {
  dispatch(slice.actions.openModal());
};

export const closeModal = () => (dispatch) => {
  dispatch(slice.actions.closeModal());
};

export default slice;
