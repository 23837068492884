import { useState, useEffect, useCallback } from "react";
import toast from "react-hot-toast";
import UploadsModalWithPhoto from "../../pages/employees/UploadsModalWithPhoto";
import Notes from "../../components/gridWrapper/notes";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  FormControl,
  Select as MuiSelect,
  MenuItem,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Modal,
} from "@material-ui/core";
import { useDispatch } from "../../store";
import { updateEmployeeList } from "../../slices/vacancies";
import useMounted from "../../hooks/useMounted";
import FileEditor from "../../aggrid/FileEditor.js";
import useSettings from "../../hooks/useSettings";
import HttpService from "../../services/HttpService.js";
import UrlService from "../../services/UrlService.js";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { AllCommunityModules } from "ag-grid-community";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import MainTableColumnDefs from "../../aggrid/MainTableColumnsDefs.js";
import { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";
import { CalendarEventForm } from "../dashboard/calendar";
import CheckboxRenderer from "../../aggrid/CheckboxRenderer.js";
import DatePicker from "../../aggrid/DatePicker.js";
import Select from "../../aggrid/Select.js";
import DeleteRenderer from "../../aggrid/DeleteRenderer.js";
import LocaleEn from "../../aggrid/locale.en.js";
import LocaleCs from "../../aggrid/locale.cs.js";
import LocaleRu from "../../aggrid/locale.ru.js";
import { useTranslation } from "react-i18next";
import MultiSelectSimple from "../../aggrid/MultiSelectSimple.js";
import { LicenseManager } from "ag-grid-enterprise";
import RichTextEditor from "../../aggrid/RichTextEditor.js";
import SpouseEditor from "../../aggrid/SpouseEditor";
import SimpleLoader from "../../components/SimpleLoader";
import HailIcon from "@mui/icons-material/Hail";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { FileUpload, NoteAlt, Send } from "@material-ui/icons";
import SendLink from "./SendLink.js";
import PrintIcon from "@mui/icons-material/Print";
import IndividualSalaryReport from "../../pages/reports/reportSalaries/IndividualSalaryReport";
import { Hidden } from "@mui/material";

LicenseManager.setLicenseKey(
  "CompanyName=GTS - Global Tungsten Solution s.r.o.,LicensedGroup=GTSDB,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-015194,ExpiryDate=23_May_2022_[v2]_MTY1MzI2MDQwMDAwMA==d202e751d68f01012278c38da399e166"
);

const Profile = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: contextData, isLoading } = useContext(DataContext);
  const [openEvent, setOpenEvent] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [vacancy, setVacany] = useState(null);
  const [noteOpen, setNoteOpen] = useState(false);
  const [fileModalOpen, setFileModalOpen] = useState(false);
  const [addSupplierOpen, setAddSupplierOpen] = useState(false);
  const [openReport, setOpenReport] = useState(false);

  const handleOpenReport = () => {
    setOpenReport(true);
  };
  const handleCloseReport = () => {
    setOpenReport(false);
  };

  const handleAddSupplierOpen = () => {
    setAddSupplierOpen(true);
  };
  const handleAddSupplierClose = () => {
    setAddSupplierOpen(false);
  };

  const handleEventClose = () => {
    setOpenEvent(false);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleEmployeeAdd = async (arr) => {
    try {
      const res = await HttpService.post(
        UrlService.apiDomain() + "api/add-employee-to-vacancy",
        {
          employee_id: props.id,
          vacancy_id: vacancy,
          state: true,
        }
      );
      if (res.status == 420) {
        handleDialogClose();
        return;
      }
      if (res.status != 200) {
        handleDialogClose();
        toast.error(t("Něco se pokazilo!"));
        return;
      }
      dispatch(
        updateEmployeeList({
          list: res.data.checklists[0].checkItems,
          cardId: vacancy,
        })
      );
      handleDialogClose();
      toast.success(t("Osoba přidána"));
    } catch (err) {
      console.error(err);
      toast.error(t("Něco se pokazilo!"));
    }
  };

  // let editable = JSON.parse(localStorage.getItem("my_roles_meta"))
  //   .filter((refData) =>
  //     [
  //       "employments-editable",
  //       "employees-editable",
  //       "assignments-editable",
  //       "visas-editable",
  //       "suppliers-editable",
  //     ].includes(refData["name"])
  //   )
  //   .reduce((acc, refData) => {
  //     acc.push(...refData.value.split("//"));
  //     return acc;
  //   }, []);

  let editable = [
    ...JSON.parse(localStorage.getItem("policy_column_tree"))["employments"]
      .update,
    ...JSON.parse(localStorage.getItem("policy_column_tree"))["employees"]
      .update,
    ...JSON.parse(localStorage.getItem("policy_column_tree"))["assignments"]
      .update,
    ...JSON.parse(localStorage.getItem("policy_column_tree"))["visas"].update,
    ...JSON.parse(localStorage.getItem("policy_column_tree"))["suppliers"]
      .update,
  ];

  var lt;
  if (t("cs") == "cs") lt = LocaleCs;
  if (t("cs") == "en") lt = LocaleEn;
  if (t("cs") == "ru") lt = LocaleRu;

  const policy_action_tree = JSON.parse(
    localStorage.getItem("policy_action_tree")
  );

  const { vacancies } = contextData;

  function sortAndFormatVacancies(vacancies) {
    vacancies.sort((a, b) => {
      const companyComparison = a.customer_name.localeCompare(b.customer_name);

      if (companyComparison === 0) {
        return new Date(a.interview_date) - new Date(b.interview_date);
      }
      return companyComparison;
    });

    const formattedVacancies = vacancies.map((vacancy) => {
      const dateParts = vacancy.interview_date.split("-");
      const formattedDate = `${dateParts[2]}.${dateParts[1]}`;
      return {
        id: vacancy.id,
        name: `${vacancy.customer_name}, ${formattedDate}, ${vacancy.vacancy_name}`,
      };
    });

    return formattedVacancies;
  }

  const sortedVacancies = sortAndFormatVacancies(vacancies);

  const get_coldefs = () => {
    return [
      {
        field: "field",
        headerName: t("Pole"),
        editable: false,
        filter: true,
        rowDrag: true,
        cellRenderer: function (params) {
          if (
            params.data.original_field == "name" ||
            params.data.original_field == "surname" ||
            params.data.original_field == "date_of_birth"
          ) {
            return "<strong>" + params.data.field + "</strong>";
          } else {
            return params.data.field;
          }
        },
      },
      {
        field: "value",
        headerName: t("Hodnota"),
        editable: function (params) {
          // if (!!params.data.joined) return false;

          var column = "";
          JSON.parse(localStorage.getItem("columns")).map((c) => {
            if (
              c.model === params.data.model &&
              c.field === params.data.original_field
            ) {
              column = c;
              return true;
            }
            return true;
          });

          if (column.disabled === 1) return false;

          if (editable.includes(column.field)) {
            return true;
          } else {
            return false;
          }
        },

        valueGetter: function (params) {
          let column = "";
          let values = [];
          JSON.parse(localStorage.getItem("columns")).map((c) => {
            if (
              c.field === params.data.original_field &&
              c.model === params.data.model
            ) {
              column = c;
              return true;
            }
            return true;
          });
          let c = column;

          if (c.column_type === "boolean") {
            try {
              return JSON.parse(localStorage.getItem("booleans")).find(
                (refData) => String(refData.id) === String(params.data?.value)
              )["name"];
            } catch (err) {
              console.log(params.data.value);
              console.log(c.field);
              console.log(err);
              return "";
            }
          } else if (c.column_type === "select") {
            if (c.field === "spouse_id") return params.data.spouse_fullname;

            if (c.field === "dodavatel") return params.data.dodavatel_fullname;

            values = [];
            let tmp_values = [];
            if (c.data_array === "custom") {
              if (c.data !== null && c.data !== "")
                values = Object.values(c.data);
            } else if (
              c.data_array === "" ||
              c.data_array === null ||
              c.data_array === "none"
            ) {
              //
            } else if (c.data_array === "vacancies") {
              values = sortedVacancies;
            } else if (c.data_array === "nabor") {
              values = contextData.nabor;
            } else {
              values = JSON.parse(localStorage.getItem(c.data_array));
            }
            // // checking if values are allowed
            // // var allowed_options = [];
            // // JSON.parse(localStorage.getItem("my_users_meta")).map((e) => {
            // //   if (e.name === "employees/columns-" + c.field) {
            // //     allowed_options = e.value.split("//");
            // //   }
            // //   return true;
            // // });
            // let allowed_options = JSON.parse(
            //   localStorage.getItem("policy_record_tree")[c.field] ?? []
            // );

            // values &&
            //   values.map((e) => {
            //     if (
            //       allowed_options.includes(e.id.toString()) ||
            //       allowed_options.length === 0 ||
            //       (allowed_options.length === 1 && allowed_options[0] === "")
            //     ) {
            //       tmp_values.push(e);
            //     }
            //     return true;
            //   });
            // values = tmp_values;
            // end of allowed values check

            if (params.data.value === null || params.data.value === "")
              return "";
            try {
              return values.find(
                (refData) => String(refData.id) == String(params.data.value)
              )["name"];
            } catch (err) {
              console.log(params.data.value);
              console.log(values);
              console.log(c);
              console.log(err);
              return "";
            }
          } else if (c.column_type === "multiselect") {
            values = [];
            if (c.data_array === "custom") {
              if (c.data !== null && c.data !== "")
                values = Object.values(c.data);
            } else if (
              c.data_array === "" ||
              c.data_array === null ||
              c.data_array === "none"
            ) {
              //
            } else {
              values = JSON.parse(localStorage.getItem(c.data_array));
            }

            if (params.data.value === null || params.data.value === "")
              return "";
            var res = "";

            let vals =
              c.field == "role"
                ? params.data.value
                : params.data.value.split("//");
            vals.map((e) => {
              try {
                res +=
                  values.find((refData) => String(refData.id) === String(e))[
                    "name"
                  ] + "//";
              } catch (err) {
                console.log(err);
              }
              return true;
            });

            return res.slice(0, -2);
          } else if (c.column_type === "date") {
            if (params.data.value === null || params.data.value === "")
              return "";
            var d = new Date(params.data.value);
            var month = d.getMonth() + 1;
            return d.getDate() + "." + month + "." + d.getFullYear();
          } else {
            return params.data.value;
          }
        },
        valueSetter: function (params) {
          if (params.newValue) {
            if (columns[params.data.original_field].column_type == "date") {
              params.data.value = params.newValue;
            } else if (
              columns[params.data.original_field].column_type == "boolean"
            ) {
              params.data.value = JSON.parse(
                localStorage.getItem("booleans")
              ).find(
                (refData) => String(refData["name"]) === String(params.newValue)
              ).id;
              return true;
            } else if (
              columns[params.data.original_field].column_type == "select"
            ) {
              let values = [];
              let tmp_values = [];
              let c = columns[params.data.original_field];
              if (c.data_array === "custom") {
                if (c.data !== null && c.data !== "")
                  values = Object.values(c.data);
              } else if (
                c.data_array === "" ||
                c.data_array === null ||
                c.data_array === "none"
              ) {
                //
              } else if (c.data_array === "vacancies") {
                values = sortedVacancies;
              } else if (c.data_array === "nabor") {
                values = contextData.nabor;
              } else {
                values = JSON.parse(localStorage.getItem(c.data_array));
              }

              // // checking if values are allowed
              // // let allowed_options = [];
              // // JSON.parse(localStorage.getItem("my_users_meta")).map((e) => {
              // //   if (e.name === "employees/columns-" + c.field) {
              // //     allowed_options = e.value.split("//");
              // //   }
              // //   return true;
              // // });
              // let allowed_options = JSON.parse(
              //   localStorage.getItem("policy_record_tree")[c.field]
              // );

              // values.map((e) => {
              //   if (
              //     allowed_options.includes(e.id.toString()) ||
              //     allowed_options.length === 0 ||
              //     (allowed_options.length === 1 && allowed_options[0] === "")
              //   ) {
              //     tmp_values.push(e);
              //   }
              //   return true;
              // });
              // // end of allowed values check

              // values = tmp_values;

              try {
                params.data.value = values.find(
                  (refData) =>
                    String(refData["name"]) === String(params.newValue)
                ).id;
                return true;
              } catch (err) {
                console.log(err);
                return true;
              }
            } else {
              params.data.value = params.newValue;
            }
          } else {
            params.data.value = "";
          }
        },

        valueFormatter: function (params) {
          var c = columns[params.data.original_field];
          if (c.column_type == "file") {
            if (params.value != null && params.value != "") {
              return "OK";
            } else {
              return "";
            }
          } else {
            return params.value;
          }
        },

        cellEditorSelector: function (params) {
          let values = [];
          let column = "";
          JSON.parse(localStorage.getItem("columns")).map((c) => {
            if (
              c.model === params.data.model &&
              c.field === params.data.original_field
            ) {
              column = c;
              return true;
            }
            return true;
          });
          var c = column;
          if (c !== "") {
            if (c.column_type === "boolean") {
              return {
                component: "agRichSelectCellEditor",
                params: {
                  values: JSON.parse(localStorage.getItem("booleans")).map(
                    (e) => e["name"]
                  ),
                  formatValue: function (params) {
                    return t(params);
                  },
                },
              };
            }

            if (c.column_type === "select") {
              let component = "agRichSelectCellEditor";
              let values = [];
              let tmp_values = [];
              if (c.data_array === "custom") {
                if (c.data !== null && c.data !== "")
                  values = Object.values(c.data);
              } else if (
                c.data_array === "" ||
                c.data_array === null ||
                c.data_array === "none"
              ) {
                //
              } else if (c.data_array === "vacancies") {
                values = sortedVacancies;
              } else if (c.data_array === "nabor") {
                values = contextData.nabor;
              } else {
                values = JSON.parse(localStorage.getItem(c.data_array));
              }

              // // checking if values are allowed
              // // var allowed_options = [];
              // // JSON.parse(localStorage.getItem("my_users_meta")).map((e) => {
              // //   if (e.name === "employees/columns-" + c.field) {
              // //     allowed_options = e.value.split("//");
              // //   }
              // //   return true;
              // // });
              // let allowed_options = JSON.parse(
              //   localStorage.getItem("policy_record_tree")[c.field]
              // );

              // values.map((e) => {
              //   if (
              //     allowed_options.includes(e.id.toString()) ||
              //     allowed_options.length === 0 ||
              //     (allowed_options.length === 1 && allowed_options[0] === "")
              //   ) {
              //     tmp_values.push(e);
              //   }
              //   return true;
              // });
              // // end of allowed values check

              // values = tmp_values;

              var pars = {
                values: values.map((e) => e["name"]),
                formatValue: function (params) {
                  return t(params);
                },
              };
              return {
                component: component,
                params: pars,
              };
            }

            if (c.column_type === "multiselect") {
              return {
                component: "MultiSelectSimple",
                params: {
                  gridType: "employees",
                },
              };
            }

            if (c.column_type === "date") {
              return {
                component: "DatePicker",
              };
            }

            if (c.column_type === "file") {
              return {
                component: "FileEditor",
                params: {
                  model: "employees",
                  field: c.field,
                },
              };
            }
          }

          return {
            component: "agTextCellEditor",
          };
        },
      },
    ];
  };

  let MainTables = JSON.parse(localStorage.getItem("main_tables"));
  let columns = {};
  JSON.parse(localStorage.getItem("columns")).map((c) => {
    for (let i in MainTables) {
      let MainTable = MainTables[i];
      if (MainTable.employees_joined == 1 || MainTable.name == "employees") {
        if (c.model == MainTable.name) {
          columns[c.field] = c;
        }
      }
    }
  });

  const mounted = useMounted();

  const { settings } = useSettings();
  const [rowdata2, setRowdata] = useState([]);
  const [pinnedrowdata, setPinnedrowdata] = useState([]);
  const [open, setOpen] = useState(false);
  const [now, setNow] = useState(Date.now());
  const [columndefs, setColumndefs] = useState(get_coldefs());
  const [gridApi, setgridApi] = useState({});
  const [loctext, setloctext] = useState(lt);
  const [gridColumnApi, setgridColumnApi] = useState({});
  const [gridOptions, setGridOptions] = useState({});
  const [photo, setPhoto] = useState("http://db.awapartners.com/nopic.jpg");

  const grd = (d) => {
    if (props.id) {
      let rd = [];
      let pinned_rd = [];
      let fotka = "";
      let data = d ?? {};

      // if (data == null) data = props.node.data;
      for (const [field, value] of Object.entries(data)) {
        if (typeof columns[field] != "undefined") {
          if (field == "spouse_id") {
            rd.push({
              field: t(columns[field].name),
              value: props.profileData.spouse_fullname,
              original_field: field,
              id: props.id,
              joined: true,
              model: columns[field].model,
            });
            continue;
          }

          if (columns[field].model !== "employees") {
            if (columns[field].model == "suppliers" && field == "dodavatel") {
              rd.push({
                field: t(columns[field].name),
                value: props.profileData.dodavatel_fullname,
                original_field: field,
                id: props.id,
                joined: true,
                model: columns[field].model,
              });
              continue;
            } else {
              rd.push({
                field: t(columns[field].name),
                value: value,
                original_field: field,
                id: props.id,
                joined: true,
                model: columns[field].model,
              });
              continue;
            }
          }
          if (
            field != "name" &&
            field != "surname" &&
            field != "date_of_birth"
          ) {
            rd.push({
              field: t(columns[field].name),
              value: value,
              original_field: field,
              id: props.id,
              model: columns[field].model,
            });
          } else {
            pinned_rd.push({
              field: t(columns[field].name),
              value: value,
              original_field: field,
              id: props.id,
              model: columns[field].model,
            });
          }
          if (field == "photo") fotka = value;
        }
      }
      let order = localStorage.getItem("profile_rows_order");
      if (order) {
        order = JSON.parse(order);

        rd.sort(function (a, b) {
          let aPos =
            order.find((i) => i.id === a.model + "_" + a.original_field)
              ?.order ?? 0;
          let bPos =
            order.find((i) => i.id === b.model + "_" + b.original_field)
              ?.order ?? 0;
          return aPos - bPos;
        });
      } else {
        rd.sort((a, b) => (a.field > b.field ? 1 : b.field > a.field ? -1 : 0));
        // rd.sort((a, b) => {
        //   if (a.joined && !b.joined) return 1;
        //   if (!a.joined && b.joined) return -1;
        //   return 0;
        // });
      }

      for (const pinned of pinned_rd) {
        rd.unshift(pinned);
      }

      setRowdata(rd);
      getPic(fotka);
    }
  };

  useEffect(() => {
    grd(props.profileData);
  }, [props.id, props.profileData]);

  /*
        useEffect(() => {


            if (rowdata2.length==0) {
                alert(props.id)
                setRowdata([{field:'id', value:props.id, original_field:'id'}]);
            }
        }, [rowdata2]);
    */

  const show_ondemand = (id, field) => {
    HttpService.get(
      UrlService.apiDomain() + "api/show_ondemand/" + props.id + "/" + field
    )
      .then((res) => {
        var node = gridApi.getRowNode(id);
        node.data.value = res.data.ondemand;
        node.setData(node.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onCellClicked = (e) => {
    var field = e.data.original_field;

    if (e.data.value == "ZOBRAZIT") {
      show_ondemand(e.node.id, field);
    }
  };

  const getContextMenuItems = (params) => {
    const result = ["copy", "paste", "export"];
    return result;
  };

  const getPic = (X) => {
    if (X != "" && X != null) {
      HttpService.post(
        UrlService.apiDomain() + "api/attachments/get_attachment",
        {
          attachment: X,
          model: "employees",
          id: props.id,
        }
      )
        .then((res) => {
          setPhoto(res.data.url);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setPhoto("http://db.awapartners.com/nopic.jpg");
    }
  };

  const defaultColDef = {
    flex: 1,
    minWidth: 170,
    editable: false,
    resizable: true,
    suppressMovableColumns: true,
    sortable: true,
    filter: true,
    suppressContextMenu: true,
  };

  const frameworkComponents = {
    CheckboxRenderer: CheckboxRenderer,
    MultiSelectSimple: MultiSelectSimple,
    DeleteRenderer: DeleteRenderer,
    DatePicker: DatePicker,
    SpouseEditor: SpouseEditor,
    FileEditor: FileEditor,
    Select: Select,
  };

  const onCellCValueChanged = (event) => {
    var send_sc = props.sc;
    if (send_sc == "") send_sc = "none";

    var data = props.profileData;
    data[event.node.data.original_field] = event.data.value;
    data.field = event.node.data.original_field;
    data.model = event.node.data.model;
    data.employee_id = event.node.data.id;

    HttpService.put(
      UrlService.apiDomain() +
        "api/employees/" +
        props.month +
        "/" +
        props.year +
        "/" +
        props.uzivatel +
        "/" +
        props.id +
        "/" +
        send_sc,
      data
    )
      .then((res) => {
        if (res.status != 200) {
          alert(
            t("Chyba serveru, prosím zkuste pozdějí") +
              ". " +
              t(res.data.message)
          );
          event.node.data.value = event.oldValue;
          event.node.setData(event.node.data);
        } else if (res.data.result == "failed") {
          alert(t(res.data.message));
          event.node.data.value = event.oldValue;
          event.node.setData(event.node.data);
        } else {
          console.log("Successfully updated");

          // props.node.setData(res.data.entry);
          // grd(res.data.entry);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onGridReady = (params) => {
    setgridApi(params.api);
    setgridColumnApi(params.columnApi);
  };

  const onRowDragEnd = (e) => {
    let order = [];
    gridApi.forEachNode((node, index) => {
      order.push({
        id: node.data.model + "_" + node.data.original_field,
        order: node.rowIndex,
      });
    });
    localStorage.setItem("profile_rows_order", JSON.stringify(order));
  };

  useEffect(() => {
    setloctext(lt);
  }, [t("cs")]);

  var header = "Profil";
  if (props.profileData && typeof props.profileData.name != "undefined")
    header = props.profileData.name + " " + props.profileData.surname;

  if (isLoading) {
    return <SimpleLoader />;
  }
  return (
    <>
      <Modal onClose={handleCloseReport} open={openReport}>
        <div>
          <IndividualSalaryReport
            name={`${props.profileData.name} ${props.profileData.surname}`}
            uzivatel={props.profileData.uzivatel}
            id={props.id}
            employee_email={props.profileData.email}
            onClose={handleCloseReport}
          />
        </div>
      </Modal>
      <Notes
        open={noteOpen}
        id={props.id}
        model={"employees"}
        onClose={() => {
          setNoteOpen(false);
        }}
      />
      <UploadsModalWithPhoto
        open={fileModalOpen}
        id={props.id}
        onClose={() => {
          setFileModalOpen(false);
        }}
      />
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 2,
        }}
      >
        <Dialog
          open={openDialog}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {t("Přidat do požadavků")}
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth>
              <MuiSelect
                id="select"
                value={vacancy}
                onChange={(event) => {
                  setVacany(event.target.value);
                }}
              >
                <MenuItem key={0} value={0}>
                  {t("Zrušení")}
                </MenuItem>
                {sortedVacancies.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </MuiSelect>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              {t("Zrušení")}
            </Button>
            <Button color="primary" autoFocus onClick={handleEmployeeAdd}>
              {t("Potvrdit")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          fullWidth
          maxWidth="sm"
          onClose={handleEventClose}
          open={openEvent}
        >
          <CalendarEventForm
            employeeName={props.profileData && props.profileData.name}
            employeeSurname={props.profileData && props.profileData.surname}
            employeeId={props.id}
            onAddComplete={handleEventClose}
            onCancel={handleEventClose}
            onDeleteComplete={handleEventClose}
            onEditComplete={handleEventClose}
            // range={selectedRange}
          />
        </Dialog>
        <Dialog
          fullWidth
          maxWidth="sm"
          onClose={handleAddSupplierClose}
          open={addSupplierOpen}
        >
          <SendLink
            handleClosePopover={handleAddSupplierClose}
            employee_phone={props.profileData && props.profileData.phone}
            employee_email={props.profileData && props.profileData.email}
            employee_id={props.id}
          />
        </Dialog>
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item xs={12} md={6} sx={{ overflow: "hidden" }}>
              <Box
                sx={{
                  width: 1,
                  display: "inline-flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography color="textPrimary" variant="h5">
                  {t(header)}
                </Typography>

                <>
                  {policy_action_tree.employees.includes("notes") && (
                    <Button
                      color="primary"
                      className="btn btn-default btn-sm delete-ingrid-but"
                      onClick={() => {
                        setNoteOpen(true);
                      }}
                      title={t("Poznámka")}
                    >
                      <NoteAlt />
                    </Button>
                  )}
                  {policy_action_tree.employees.includes("docs") && (
                    <Button
                      color="primary"
                      onClick={() => {
                        setFileModalOpen(true);
                      }}
                      title={t("Dokumenty")}
                    >
                      <FileUpload />
                    </Button>
                  )}
                  {policy_action_tree.interactions.includes("browse") && (
                    <Button
                      color="primary"
                      onClick={() => setOpenEvent(true)}
                      title={t("Přidat interakci")}
                    >
                      <CalendarMonthIcon />
                    </Button>
                  )}
                  {policy_action_tree.vacancies.includes("add_man") && (
                    <Button
                      color="primary"
                      onClick={handleDialogOpen}
                      title={t("Požadávek lidí")}
                    >
                      <HailIcon />
                    </Button>
                  )}
                  {policy_action_tree.employees.includes("add_supplier") && (
                    <Button
                      color="primary"
                      onClick={handleAddSupplierOpen}
                      title={t("Udelat dodavatelem")}
                    >
                      <Send />
                    </Button>
                  )}
                  {policy_action_tree.salaries.includes("report") && (
                    <Button
                      color="primary"
                      onClick={handleOpenReport}
                      title={t("Individuální report")}
                    >
                      <PrintIcon />
                    </Button>
                  )}
                </>
              </Box>
              <Box sx={{ mt: 3, height: "100%", pb: "56px", minHeight: '500px' }}>
                <div
                  style={{ width: "100%", overflow: "auto", height: "100%" }}
                  id="myGridProfile"
                  className="ag-theme-material"
                >
                  <AgGridReact
                    rowDragManaged={true}
                    animateRows={true}
                    getRowStyle={(params) => {
                      if (editable.includes(params.data.original_field)) {
                        return null;
                      } else {
                        return { background: "rgba(47,47,47,0.1)" };
                      }
                    }}
                    getContextMenuItems={getContextMenuItems}
                    modules={AllCommunityModules}
                    rowData={rowdata2}
                    columnDefs={columndefs}
                    defaultColDef={defaultColDef}
                    onGridReady={onGridReady}
                    onCellValueChanged={onCellCValueChanged}
                    onRowDragEnd={onRowDragEnd}
                    frameworkComponents={frameworkComponents}
                    enableCellChangeFlash={true}
                    // rowSelection={"multiple"}
                    // suppressContextMenu={true}
                    localeText={loctext}
                    popupParent={document.querySelector("body")}
                    headerHeight={0}
                    onCellClicked={onCellClicked}
                  />
                </div>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: {
                  xs: "none",
                  md: "flex",
                },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={photo} style={{ width: "100%", height: "auto" }} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Profile;
