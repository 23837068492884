import UIGridRendererButtonOpenT from "../../Infrastructure/UI/Grid/Renderer/Button/Open/UIGridRendererButtonOpenT.mjs";
import UIModalFileGridT from "../../Infrastructure/UI/Modal/FileGrid/UIModalFileGridT.mjs";
import JSONRPCRequestT from "../../Type/JSONRPC/JSONRPCRequestT.mjs";

import { APIClient } from "../../Main.mjs";
/*import EnvMechNodeJST from "../../Infrastructure/Env/EnvMechNodeJST.mjs";

import HTTPClientXMLHTTPRequestT from "../../Infrastructure/Network/HTTP/Client/HTTPClientXMLHTTPRequestT.mjs";

import DBLocalStorageT from "../../Infrastructure/DB/DBLocalStorageT.mjs";

import UIGridEditorTextReactT from "../../Infrastructure/UI/Grid/Editor/Text/UIGridEditorTextReactT.mjs";
import UIGridEditorSelectReactT from "../../Infrastructure/UI/Grid/Editor/Select/UIGridEditorSelectReactT.mjs";
import UIGridEditorDateReactT from "../../Infrastructure/UI/Grid/Editor/Date/UIGridEditorDateReactT.mjs";
import UIGridEditorDatetimeReactT from "../../Infrastructure/UI/Grid/Editor/Datetime/UIGridEditorDatetimeReactT.mjs";

import UIGridRendererBooleanReactT from "../../Infrastructure/UI/Grid/Renderer/Boolean/UIGridRendererBooleanReactT.mjs";
import UIGridRendererButtonReactT from "../../Infrastructure/UI/Grid/Renderer/Button/UIGridRendererButtonReactT.mjs";

import UIGridProducerT from "../../Infrastructure/UI/Grid/UIGridProducerT.mjs";

import UIPageContentProducerT from "../../Infrastructure/UI/Page/Content/UIPageContentProducerT.mjs";
import UIPageContentTopBarProducerT from "../../Infrastructure/UI/Page/Content/TopBar/UIPageContentTopBarProducerT.mjs";
import UIPageContentTopBarFilterSwitcherProducerT from "../../Infrastructure/UI/Page/Content/TopBar/FilterSwitcher/UIPageContentTopBarFilterSwitcherProducerT.mjs"; 
import UIButtonProducerT from "../../Infrastructure/UI/Button/UIButtonProducerT.mjs"; 
import UISVGProducerT from "../../Infrastructure/UI/SVG/UISVGProducerT.mjs"; 
import UIBooleanProducerT from "../../Infrastructure/UI/Boolean/UIBooleanProducerT.mjs";
import UILabelProducerT from "../../Infrastructure/UI/Label/UILabelProducerT.mjs";


import StorageT from "../../Interface/Storage/StorageT.mjs";
import EnvT from "../../Interface/Env/EnvT.mjs";
import JSONRPCClientT from "../../Interface/Network/JSONRPC/Client/JSONRPCClientT.mjs";


import ViewPageEmploymentDepartmentInfosT from "../../Interface/View/Page/EmploymentDepartment/Info/ViewPageEmploymentDepartmentInfosT.mjs";
import ViewPageEmploymentDepartmentNoticesT from "../../Interface/View/Page/EmploymentDepartment/Notice/ViewPageEmploymentDepartmentNoticesT.mjs";


import EnvURLT from "../../Procedure/Env/EnvURLT.mjs";
import APIClientT from "../../Procedure/API/APIClientT.mjs";


import HTTPHeaderT from "../../Type/HTTP/Header/HTTPHeaderT.mjs";
import HTTPHeadersT from "../../Type/HTTP/Header/HTTPHeadersT.mjs";*/

/*const EnvMechNodeJS = new EnvMechNodeJST( 

);

const Env = new EnvT( 
	EnvMechNodeJS
);

const EnvURL = new EnvURLT( 
	Env
);


const DBLocalStorage = new DBLocalStorageT(

);

const Storage = new StorageT(
	DBLocalStorage
);


const HTTPClient = new HTTPClientXMLHTTPRequestT(

);

const JSONRPCClient = new JSONRPCClientT(
	HTTPClient,
	EnvURL.Get( ) + "api/json_rpc"
);

const APIClient = new APIClientT(
	JSONRPCClient,
	Storage
);*/

import { useTranslation } from "react-i18next";
import LocaleCs from "../../aggrid/locale.cs";
import LocaleEn from "../../aggrid/locale.en";
import LocaleRu from "../../aggrid/locale.ru";
import useSettings from "../../hooks/useSettings";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import toast from "react-hot-toast";
import React, {
  useCallback,
  useEffect,
  useState,
  Children,
  cloneElement,
  memo,
} from "react";
import HttpService from "../../services/HttpService";
import UrlService from "../../services/UrlService";
import { Helmet } from "react-helmet-async";
import { Delete } from "@material-ui/icons";
import {
  Autocomplete,
  TextField,
  Box,
  Button,
  IconButton,
  Container,
  Grid,
  LinearProgress,
  ThemeProvider,
  Typography,
  Popover,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import SaveIcon from "@mui/icons-material/Save";
import { AgGridReact } from "ag-grid-react";
import { AllCommunityModules } from "ag-grid-community";
import addPropsToGrid from "../../utils/addPropsToGrid";
import getColumnDefinitions from "../../services/getColumnDefinitions";
import "ag-grid-enterprise";
import { LicenseManager } from "ag-grid-enterprise";
import apiGet from "../../api/apiGet";
import apiPost from "../../api/apiPost";
import MultiFileModal from "./multiFileModal";
import Select from "../../aggrid/Select";
import FileEditor from "../../aggrid/FileEditor";
import CheckboxRenderer from "../../aggrid/CheckboxEditor";
import { OpenInNew } from "@material-ui/icons";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteRenderer from "../../aggrid/DeleteRendererNew";
import OptionsRenderer from "../../aggrid/OptionsRenderer";
import DownloadAttachment from "../../aggrid/downloadAttachment";
import DatePicker from "../../aggrid/DatePicker";
import DateTimePicker from "../../aggrid/DateTimePicker";
import useFilter from "../../hooks/useFilter";
import MultiSelect from "../../aggrid/MultiSelect";
import { createTheme } from "@material-ui/core/styles";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import {
  addToDataArray,
  updateDataInArray,
} from "../../utils/localStorageUtills";
import DateModal from "../dateModal/dateModal";
import JsxRenderer from "../../aggrid/JsxRenderer";
import ExternalLink from "../../icons/ExternalLink";
import Notes from "./notes";
import { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";
import SimpleLoader from "../SimpleLoader";
import LinkToFotoRenderer from "../../utils/LinkToFotoRenderer.js";
import EmployeeCard from "../employee_card";
import CustomerCard from "../../pages/customers/CustomerCard";
import CopyVacancy from "../dashboard/vacancies/CopyVacancy.js";
import Audit from "../../components/audit";
import { getName, strComparator } from "../../services/getColumnDefinitions";
import TextArea from "../../aggrid/TextArea.js";

LicenseManager.setLicenseKey(
  "CompanyName=GTS - Global Tungsten Solution s.r.o.,LicensedGroup=GTSDB,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-015194,ExpiryDate=23_May_2022_[v2]_MTY1MzI2MDQwMDAwMA==d202e751d68f01012278c38da399e166"
);

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#3f51b5",
    },
    secondary: {
      main: "#f50057",
    },
  },
});

let fileAccountingRequestId = null;

const defaultFrameworkComponents = {
  Select: Select,
  FileEditor: FileEditor,
  CheckboxRenderer: CheckboxRenderer,
  OpenInNewRenderer: (params) => {
    //TODO
    return <OpenInNew sx={{ color: params.value ? "#4caf50" : "#5664d2" }} />; //TODO
  }, //TODO
  UIGridRendererButtonOpenT: UIGridRendererButtonOpenT,
  DeleteRenderer: DeleteRenderer,
  OptionsRenderer: OptionsRenderer,
  DownloadAttachment: DownloadAttachment,
  DatePicker: DatePicker,
  DateTimePicker: DateTimePicker,
  MultiSelect: MultiSelect,
  JsxRenderer: JsxRenderer,
  LinkToFotoRenderer: LinkToFotoRenderer,
  TextArea: TextArea,
  CopyVacancy: CopyVacancy,
};

export default memo(function GridWrapper({
  children,
  autoGroupColumnDef,
  withoutExcel,
  model,
  data,
  colDefs,
  helmet,
  title,
  frameworkComponents,
  gridProps,
  pinned,
  clickable,
  style,
  handleRowDataOnInit,
  interceptChange,
  interceptDelete,
  onUpdateResponse,
  hidden,
  defaultSort,
  defaultSortOrder,
  small,
  filter: handleRowData,
  getRowStyle,
  handleColumnDefs,
  onSelected,
  historyYear,
  historyMonth,
  needTemplate,
  forApproval,
  month: propsMonth,
  year: propsYear,
  browseData,
  rowSelection,
  interceptClick,
  usersData,
  needResize = true,
  withoutFilterReset,
}) {
  const { t } = useTranslation();
  const filter = useFilter();
  let lt;
  if (t("cs") === "cs") lt = LocaleCs;
  else if (t("cs") === "en") lt = LocaleEn;
  else if (t("cs") === "ru") lt = LocaleRu;

  const policy_action_tree = JSON.parse(
    localStorage.getItem("policy_action_tree")
  );

  const userId = JSON.parse(localStorage.getItem("my_employee")).id;

  const [rowData, setRowData] = useState(null);
  const [rowDataFilter, setRowDataFilter] = useState(() => (i) => {
    return true;
  });
  const [filteredData, setFilteredData] = useState([]);

  const [locText, setLocText] = useState(lt);

  const [selectedRows, setSelectedRows] = useState([]);

  const { data: contextData, isLoading } = useContext(DataContext);

  useEffect(() => {
    if (onSelected) {
      onSelected(selectedRows);
    }
  }, [onSelected, selectedRows]);

  useEffect(() => {
    setLocText(lt);
  }, [lt]);

  const [ready, setReady] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [columnDefs, setColumnDefs] = useState(colDefs ?? []);

  const [multiFileData, setMultiFileData] = useState(null);

  const [year, setYear] = useState(null);
  const [month, setMonth] = useState(null);
  const [openEployee, setOpenEmployee] = useState(false);
  const [noteOpen, setNoteOpen] = useState(false);
  const [noteId, setNoteId] = useState(null);
  const [employeeId, setEmployeeId] = useState(null);
  const [employeeModel, setEmployeeModel] = useState("profile");
  const [showAudit, setShowAudit] = useState(false);
  const [showAuditId, setShowAuditId] = useState(0);

  const [dataUsers, setDataUsers] = useState(usersData);
  const [dataPropositions, setDataPropositions] = useState(data ? {} : null);

  const [largeTextOpen, setLargeTextOpen] = useState(false);
  const [largeText, setLargeText] = useState("");

  const [openCustomer, setOpenCustomer] = useState(false);
  const [customerId, setCustomerId] = useState(null);

  const handleCloseCustomer = () => {
    setOpenCustomer(false);
  };

  const handleCloseLargeText = () => {
    setLargeTextOpen(false);
  };

  const OnModalFileGridOpen = () => {
    console.log("OnModalFileGridOpen", fileAccountingRequestId);

    let Id = fileAccountingRequestId;

    APIClient.Request(
      new JSONRPCRequestT(
        "accounting_request_files" + "." + "browse_by_accounting_request_id",
        {
          accounting_request_id: Id,
          include_deleted: false,
        },
        false
      ),

      OnAPIClientFileRequestSuccess.bind(this),

      OnAPIClientFileRequestError.bind(this)
    );

    //FilesRequest( );
  };

  const OnModalFileGridClose = () => {
    console.log("OnClose");

    UIModalFileGrid.PropSet("GridData", []);

    UIModalFileGrid.PropSet("ModalDialogIsOpen", false);
  };

  const [UIModalFileGrid, setUIModalFileGrid] = useState(
    new UIModalFileGridT({
      Key: "file_grid",
      ModalTitle: "Priloha",
      ModalDialogOnOpenCb: OnModalFileGridOpen,
      ModalDialogOnCloseCb: OnModalFileGridClose,
      ModalDialogMaxWidth: "xl",
      OnUpload: function (Files) {
        console.log("OnUpload", Files);

        let Id = fileAccountingRequestId;

        APIClient.Upload(
          81, //accounting_request_files
          Id,
          Files,

          function (JSONRPCResponse) {
            UIModalFileGrid.RowsAdd(JSONRPCResponse.Result());

            UIModalFileGrid.FilesClear();
          },
          function (Err) {
            alert(Err.message);
          }
        );
      },
      OnDownload: function (Id, Filename) {
        APIClient.Download(
          81, //accounting_request_files
          Id,

          function (Blob) {
            const A = document.createElement("a");

            A.setAttribute("download", Filename);

            A.href = Blob;

            A.hidden = true;

            A.setAttribute("target", "_blank");

            A.click();

            URL.revokeObjectURL(Blob);
          },
          function (Err) {}
        );
      },
      OnDelete: function (Id) {
        APIClient.Request(
          new JSONRPCRequestT(
            "accounting_request_files" + "." + "delete_by_id", //TODO Generic
            {
              id: Id,
            },
            false
          ),

          function (JSONRPCResponse) {
            console.log(JSONRPCResponse);

            UIModalFileGrid.RowsDelete([Id]);
          },
          function (Err) {}
        );
      },
    })
  );

  const OnAPIClientFileRequestSuccess = function (JSONRPCResponse) {
    console.log("OnAPIClientFileRequestSuccess", JSONRPCResponse.Result());

    UIModalFileGrid.PropSet("GridData", JSONRPCResponse.Result());
  };

  const OnAPIClientFileRequestError = function (Err) {
    console.log("OnAPIClientFileRequestError", Err);
  };

  let UIModalFileGridReact = UIModalFileGrid.Component();

  const handleCloseEployee = () => {
    setOpenEmployee(false);
  };
  const handleOpenEmployee = () => {
    setOpenEmployee(true);
  };

  let date = "now/now";

  if (propsMonth && propsYear) {
    date = `${propsYear}/${propsMonth}`;
  } else {
    if (year && month) {
      date = `${year}/${month}`;
    }
  }

  useEffect(() => {
    let filterYear = filter.getYearFilter();
    let monthFilter = filter.getMonthFilter();

    if (!historyYear || !historyMonth) {
      if (filterYear !== year) {
        setYear(filterYear);
      }
      if (monthFilter !== month) {
        setMonth(monthFilter);
      }
    }
  }, [filter]);

  const defaultColDef = {
    flex: 1,
    minWidth: 130,
    editable: false,
    resizable: true,
    suppressMovableColumns: true,
    sortable: true,
    filter: "agTextColumnFilter",
    menuTabs: ["filterMenuTab", "columnsMenuTab"],
    cellStyle: (params) => {
      if (params.colDef.editable == false) {
        return { background: "#eaeaea" };
      }
    },
  };

  useEffect(async () => {
    let mounted = true;
    if (data === undefined) {
      !isLoading && (await getData(model, date, browseData, mounted));
    }
    return function cleanup() {
      mounted = false;
    };
  }, [isLoading, model, propsMonth, propsYear, browseData]);

  /*useEffect( ( ) => {
		
		if( fileAccountingRequestId === null ){
			
			return;
			
		}
	  
		console.log( "useEffect", fileAccountingRequestId );
		
		UIModalFileGrid.PropSet( "ModalDialogIsOpen", true );
	  
	}, [ fileAccountingRequestId ] );*/

  useEffect(async () => {
    let mounted = true;

    if (!colDefs) {
      if (
        // columnDefs.length === 0 &&
        isLoading === false &&
        dataUsers &&
        rowData &&
        dataPropositions
      ) {
        await getColumnDefinitions(
          t,
          model,
          contextData,
          false,
          (a) => a,
          null,
          true
        ).then((d) => {
          if (mounted) {
            if (!d || d.length === 0) {
              alert("Žádné kolonky v modelu " + model);
              d = [];
            }
            if (pinned) {
              d.find((e) => e.field === pinned).pinned = "left";
            }
            if (hidden) {
              d = d.filter((def) => !hidden.includes(def.field));
            }
            if (policy_action_tree[model].includes(`delete`)) {
              d.push({
                headerName: t("Odstranit"),
                cellRendererSelector: function (params) {
                  if (params.node.group) {
                    return;
                  } else {
                    return {
                      component: "DeleteRenderer",
                      params: {
                        interceptDelete: interceptDelete,
                        model: model,
                        gridType: model,
                        setRowData,
                      },
                    };
                  }
                },
                suppressMenu: true,
                editable: false,
                resizable: false,
                sortable: false,
                minWidth: 100,
                maxWidth: 100,
              });
            }
            if (model !== "customer_reports") {
              d.push({
                headerName: "#",
                valueGetter: "node.rowIndex + 1",
                lockPosition: "left",
                pinned: "left",
                maxWidth: 150,
                sortable: false,
                resizable: false,
                suppressMenu: true,
                checkboxSelection: true,
                headerCheckboxSelection:
                  rowSelection && rowSelection === "single" ? false : true,
                headerCheckboxSelectionFilteredOnly: true,
              });
            }

            if (
              model !== "customers" &&
              model !== "customer_interactions" &&
              model !== "customer_start_requirement_conditions" &&
              model !== "customer_start_requirements" &&
              model !== "customer_start_requirement_propositions" &&
              model !== "downpayments" &&
              model !== "dailycoordinator_reports" &&
              model !== "customer_reports"
            ) {
              d.push({
                minWidth: 100,
                headerName: t("Poznámky"),
                field: "note",
                pinned: "left",
                cellRenderer: "JsxRenderer",
                cellRendererParams: {
                  jsx: (props) => (
                    <>
                      <Button
                        sx={{ ml: "-10px", width: "64px" }}
                        color="primary"
                        id="fil_attach"
                        className="btn btn-default btn-sm delete-ingrid-but"
                        fullWidth
                        onClick={() => {
                          setNoteId(props.data.id);
                          setNoteOpen(true);
                        }}
                      >
                        <ExternalLink />
                      </Button>
                      {props.value}
                    </>
                  ),
                },
              });
            }
            for (const columnDef of d) {
              if (
                columnDef.field == "customer_start_requirement_proposition_id"
              ) {
                columnDef.cellRenderer = (params) => {
                  if (params.data.customer_start_requirement_proposition_id) {
                    return dataPropositions[
                      params.data.customer_start_requirement_proposition_id
                    ]?.text;
                  }
                };

                columnDef.editable = (params) => {
                  return params.data.customer_start_requirement_proposition_id
                    ? false
                    : true;
                };
              }

              if (columnDef.field == "files") {
                console.log(columnDef);

                columnDef.cellRendererParams = {
                  OnClick: function (Button, Data, Node) {
                    fileAccountingRequestId = Data.id;

                    UIModalFileGrid.PropSet("ModalDialogIsOpen", true);
                  },
                };
              }

              if (
                columnDef?.cellEditorParams?.data_array_model == "employees"
                // columnDef.field == "created_by" ||
                // columnDef.field == "updated_by"
              ) {
                console.log({ columnDef });
                columnDef.cellEditorParams.data_array = dataUsers;
                columnDef.comparator = (a, b) => {
                  let items = dataUsers;
                  if (!items) {
                    return strComparator(a, b);
                  }
                  a = items.find((i) => i?.id === a);
                  b = items.find((i) => i?.id === b);
                  return strComparator(getName(a), getName(b));
                };

                columnDef.valueFormatter = function (params) {
                  let val;
                  if (val?.toString() === "0") return getName(0, "");

                  val =
                    dataUsers.find(
                      (item) =>
                        item?.id?.toString() === params?.value?.toString()
                    ) ?? "";

                  if (Array.isArray(params.value)) {
                    let ids = params?.value;

                    let result = "";
                    if (!ids) return "";
                    for (const id of ids) {
                      if (id?.toString() === "0") {
                        result += getName(0, null) + ", ";
                        continue;
                      }

                      result +=
                        getName(
                          dataUsers.find((item) => item?.id?.toString() == id),
                          null
                        ) + ", ";
                      if (result.length > 100) {
                        result = result.substring(0, result.length - 2) + "...";
                        break;
                      }
                    }
                    return result.substring(0, result.length - 2);
                  }

                  return getName(val);
                };
                columnDef.filter = "agSetColumnFilter";
                columnDef.filterParams = {
                  valueFormatter: columnDef.valueFormatter,
                  comparator: (a, b) => {
                    // let items = JSON.parse(localStorage.getItem(def.data_array));
                    let items = dataUsers;
                    console.log({ items });
                    if (!items) {
                      return strComparator(a, b);
                    }
                    a = items.find((i) => i?.id == a);
                    b = items.find((i) => i?.id == b);
                    return strComparator(getName(a), getName(b));
                  },
                };
              }
              let sort = defaultSort ?? "updated_at";
              if (columnDef.field === sort) {
                columnDef.sort = defaultSortOrder ?? "desc";
              }
            }
            if (handleColumnDefs) {
              setColumnDefs(handleColumnDefs(d.filter((i) => i != false)));
            } else {
              setColumnDefs(d.filter((i) => i != false));
            }
          }
        });
      }
    }

    return function cleanup() {
      mounted = false;
    };
  }, [
    model,
    handleRowDataOnInit,
    isLoading,
    dataUsers,
    rowData,
    dataPropositions,
  ]);

  const getData = async (model, date, browseData, mounted) => {
    try {
      const res = await HttpService.post(
        UrlService.apiDomain() + `api/${model}/get/all`,
        {
          data: browseData,
        }
      );

      if (!res.data.success) {
        alert(res.data.message);
        setReady(true);
        return;
      }
      if (mounted) {
        const data = Object.values(res.data.data) ?? [];
        setRowData(handleRowDataOnInit ? handleRowDataOnInit(data) : data);
        setDataUsers(Object.values(res.data.data_employees));
        setDataPropositions(res.data.data_propositions ?? {});
        setReady(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (data) {
      setRowData(handleRowDataOnInit ? handleRowDataOnInit(data) : data);
      setReady(true);
    }
  }, [data]);

  useEffect(() => {
    if (colDefs) {
      setColumnDefs(colDefs);
      setReady(true);
    }
  }, [colDefs]);

  const show_ondemand = (id, field) => {
    HttpService.get(
      UrlService.apiDomain() +
        "api/" +
        model +
        "/show_on_demand/" +
        id +
        "/" +
        field
    )
      .then((res) => {
        let node = gridApi.getRowNode(id - 1);
        node.data[field] = res.data.value;
        node.setData(node.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onCellClicked = (e) => {
    if (interceptClick) {
      interceptClick(e);
      return;
    }
    if (!e.node.group) {
      let field = e.colDef.field;
      if (e.data[field] === "ZOBRAZIT") {
        show_ondemand(e.data.id, field);
      }

      if (field === "feedback_text") {
        setLargeText(e.data[field]);
        setLargeTextOpen(true);
      }
      console.log(e);
      if (e.colDef.column_type !== "multifile") {
        clickable?.find((i) => i.field === field)?.onClick(e.data, e);
      } /*else {
		  
		UIModalFileGrid.PropSet( "Id", e.data.id );
		  
		UIModalFileGrid.PropSet( "ModalDialogIsOpen", true );
		  
	  }*/

      if (e.colDef.column_type === "multifile") {
        /*setMultiFileData({
          id: e.data.id,
          field: field,
          model: model,
          label: e.colDef.headerName,
        });*/
      }
      if (field == "surname" && model != "clients") {
        if (model == "medical_checkups") {
          setEmployeeModel("medical_checkups");
        }
        setEmployeeId(e.data.employee_id);
        handleOpenEmployee();
      }
      if (field == "customer_id" && model == "customer_reports") {
        setCustomerId(e.data.customer_id);
        setOpenCustomer(true);
      }
    }
  };

  const [pendingChange, setPendingChange] = useState(null);

  const onCellValueChanged = (e) => {
    if (interceptChange) {
      interceptChange(e);
      return;
    }
    let field = e.colDef.field;
    let newValue = e.newValue;
    let oldValue = e.oldValue;
    let id = e.data.id;

    if (newValue === true) {
      newValue = 1;
    } else if (newValue === false) {
      newValue = 0;
    }
    let sendData = {
      [field]: newValue,
      id,
    };

    let data_array = [
      { key: "id", value: id },
      { key: "field", value: field },
      { key: "value", value: newValue },
    ];

    if (e.colDef.save_history !== "false" && e.colDef.save_history) {
      setPendingChange({ data: data_array, e: e });
      return;
    }

    if (field == "customer_start_requirement_proposition_id") {
      console.log({ newValue });
      handleAddProposition(id, userId, newValue);
      return;
    }

    postUpdate(sendData).then((newData) => {
      if (!newData) {
        e.node.data[field] = e.oldValue;
        e.node.setData(e.node.data);
        return;
      }

      let row = gridApi?.getRowNode(e.node.id);
      if (!row) return;

      row.setData(newData);
    });
  };

  const handleAddProposition = async (
    customer_start_requirement_id,
    proposer_employee_id,
    newValue
  ) => {
    try {
      const firstResponse = await HttpService.post(
        UrlService.apiDomain() +
          "api/customer_start_requirement_propositions/create",
        {
          data: {
            customer_start_requirement_id,
            proposer_employee_id,
            text: newValue,
          },
        }
      );
      if (!firstResponse.data.success) {
        alert(firstResponse.data.message);
        return;
      }

      const customer_start_requirement_proposition_id =
        firstResponse.data.data.id;

      const secondResponse = await HttpService.post(
        `${UrlService.apiDomain()}api/customer_start_requirements/edit`,
        {
          data: {
            id: customer_start_requirement_id,
            customer_start_requirement_proposition_id,
            resolver_employee_id: proposer_employee_id,
            is_done: true,
          },
        }
      );
      if (!secondResponse.data.success) {
        alert(secondResponse.data.message);
        return;
      }
      let browseData = {
        type: "owner",
        customer_id: secondResponse.data.data.customer_id,
      };
      getData("customer_start_requirements", null, browseData, true);
    } catch (error) {
      console.error(error);
    }
  };

  const postUpdate = async (sendData) => {
    let url = `api/${model}/edit`;

    try {
      const res = await HttpService.post(UrlService.apiDomain() + url, {
        data: sendData,
      });
      if (!res.data.success) {
        alert(res.data.message);
        return;
      } else {
        updateDataInArray(model, res.data.data);
        if (onUpdateResponse) {
          onUpdateResponse(res.data);
        }
        return res.data.data;
      }
    } catch (error) {
      console.log(error);
    }

    // return apiPost(
    //   url,
    //   data_array,
    //   (response) => {
    //     console.log("Success: ", response.data);
    //     updateDataInArray(model, response.data);
    //     if (onUpdateResponse) {
    //       onUpdateResponse(response);
    //     }
    //     return response.data;
    //   },
    //   (error, response) => {
    //     alert("ERROR: " + error);
    //     if (response.data) {
    //       return response.data;
    //     }
    //     console.error("Response with ERROR: ", response);
    //   }
    // );
  };

  const handleAdd = (item) => {
    let newData = rowData ? rowData.concat(item) : [item];
    setRowData(newData);
    if (model === "issued_invoices") {
      clickable[0]?.onClick();
    }
    addToDataArray(model, item);
  };

  const resizeAll = () => {
    if (gridColumnApi && needResize) {
      const allColumnIds = [];
      if (gridColumnApi.getAllColumns() !== undefined) {
        gridColumnApi.getAllColumns().forEach((column) => {
          allColumnIds.push(column.getId());
        });
        gridColumnApi.autoSizeColumns(allColumnIds, false);
      }
    }
  };

  useEffect(() => {
    if (!rowData) {
      setFilteredData(null);
      return;
    }
    let tempData = filter
      .byAccessAndFilter(rowData, model)
      .filter(rowDataFilter);
    if (handleRowData) {
      tempData = handleRowData(tempData);
    }
    setFilteredData(tempData);
  }, [handleRowData, rowData, filter, model, rowDataFilter]);

  // useEffect(() => {
  //   if (!rowData) {
  //     setFilteredData([]);
  //     return;
  //   }
  //   let tempData = rowData.filter(rowDataFilter);

  //   if (handleRowData) {
  //     tempData = handleRowData(tempData);
  //   }
  //   setFilteredData(tempData);
  // }, [handleRowData, rowData, rowDataFilter]);

  let body = addPropsToGrid(children, {
    onAdd: handleAdd,
    selected: selectedRows,
    setFilter: setRowDataFilter,
    rowData: rowData,
    getData: getData,
    setRowData: setRowData,
  });
  const [selectedTemplate, setSelectedTemplate] = useState(
    localStorage.getItem(`selected_template`) ?? "default"
  );

  useEffect(() => {
    localStorage.setItem(`selected_template`, selectedTemplate);
  }, [selectedTemplate]);

  const [columnState, setColumnState] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onFirstDataRendered = useCallback(
    (params) => {
      const columnFilter = JSON.parse(
        localStorage.getItem(`${model}_filter_state`)
      );
      if (columnFilter) {
        gridApi.setFilterModel(columnFilter);
      }
      if (columnState !== null && gridColumnApi) {
        gridColumnApi.setColumnState(columnState);
      }
    },
    [gridApi, columnState, gridColumnApi]
  );

  const onDragStopped = (params) => {
    console.log({ params });
    setColumnState(params.columnApi.getColumnState());
  };

  const onFilterChanged = (params) => {
    const columnFilter = JSON.stringify(params.api.getFilterModel());
    localStorage.setItem(`${model}_filter_state`, columnFilter);
  };

  const onColumnVisible = (params) => {
    setColumnState(params.columnApi.getColumnState());
  };
  const [template, setTemplate] = useState({});
  const [open, setOpen] = useState(false);
  const [templateName, setTemplateName] = useState([]);
  const [templateLable, setTemplateLable] = useState("");

  const lable = templateLable ? templateLable : "all";

  useEffect(() => {
    if (!needTemplate) return;
    HttpService.get(UrlService.apiDomain() + `api/get-templates`)
      .then((response) => {
        const loaded = response.data ?? [];
        loaded.push({
          label: t("Nový"),
          value: "default",
          data: null,
        });
        setTemplateName(loaded);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (!needTemplate) return;
    HttpService.get(
      UrlService.apiDomain() +
        `api/templates/${model}/table/${selectedTemplate}/${lable}`
    )
      .then((response) => {
        setTemplate(response.data);
        if (response.data.data) {
          setColumnState(JSON.parse(response.data.data));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [selectedTemplate]);

  useEffect(() => {
    if (columnState !== null && gridColumnApi) {
      gridColumnApi.setColumnState(columnState);
    }
  }, [columnState, gridColumnApi]);

  function addRemoveButtonToEndAdornment(endAdornment) {
    const children = [
      ...Children.toArray(endAdornment.props.children),
      <IconButton
        key={"delete"}
        sx={{ p: 0 }}
        onClick={() => {
          handleClickOpen();
        }}
      >
        <Delete />
      </IconButton>,
    ];

    return cloneElement(endAdornment, {}, children);
  }

  function addSaveButtonToEndAdornment(endAdornment) {
    const children = [
      ...Children.toArray(endAdornment.props.children),
      <IconButton
        key={"save"}
        sx={{ p: 0 }}
        onClick={() => {
          saveTemplate({
            value: selectedTemplate,
            label: templateLable,
            data: columnState,
          });
        }}
      >
        <SaveIcon />
      </IconButton>,
    ];

    return cloneElement(endAdornment, {}, children);
  }

  const saveTemplate = async (data) => {
    await HttpService.post(
      UrlService.apiDomain() + `api/templates/${model}/table`,
      data
    )
      .then(toast.success("Šablona byla úspěšně uložena"))
      .catch((error) => {
        toast.error(error);
        console.log(error);
      });
  };

  const deleteTemplate = async () => {
    try {
      const result = await HttpService.delete(
        UrlService.apiDomain() + `api/templates/${selectedTemplate}`
      );
      setTemplateName(
        templateName.filter((template) => template.value !== selectedTemplate)
      );
      setSelectedTemplate("default");
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (frameworkComponents) {
    frameworkComponents = {
      ...frameworkComponents,
      defaultFrameworkComponents,
    };
  } else {
    frameworkComponents = defaultFrameworkComponents;
  }

  const getContextMenuItems = (params) => {
    const result = ["copy", "paste", "export"];
    if (policy_action_tree.employees.includes("audit")) {
      result.push("separator");
      result.push({
        name: "Audit",
        checked: true,
        action: function () {
          setShowAudit(true);
          setShowAuditId(params.node.data.id);
        },
        icon: '<img src="https://www.ag-grid.com/example-assets/skills/mac.png"/>',
      });
    }
    return result;
  };

  let table = (
    <div
      id="myGrid"
      className="ag-theme-material"
      style={
        small
          ? { width: "100%", height: "35vh", minHeight: "100%" }
          : {
              width: "100%",
              height: "50vh",
              minHeight: "100%",
            }
      }
    >
      <ThemeProvider theme={theme}>
        <AgGridReact
          style={{ width: 500, height: 200 }}
          onFirstDataRendered={onFirstDataRendered}
          autoGroupColumnDef={autoGroupColumnDef}
          modules={AllCommunityModules}
          onFilterChanged={onFilterChanged}
          rowData={filteredData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          onDragStopped={onDragStopped}
          onColumnVisible={onColumnVisible}
          onCellValueChanged={onCellValueChanged}
          getContextMenuItems={getContextMenuItems}
          onCellClicked={onCellClicked}
          onSelectionChanged={() => setSelectedRows(gridApi.getSelectedRows())}
          onSortChanged={(e) => {
            e.api.refreshCells();
          }}
          // onGridColumnsChanged={() => {
          //   resizeAll();
          // }}
          onRowDataChanged={() => {
            resizeAll();
          }}
          getRowStyle={getRowStyle}
          enableCellChangeFlash={true}
          rowSelection={rowSelection ? rowSelection : "multiple"}
          // suppressContextMenu={true}
          frameworkComponents={frameworkComponents}
          localeText={locText}
          popupParent={document.querySelector("body")}
          {...gridProps}
        />
      </ThemeProvider>
    </div>
  );
  if (isLoading) {
    return <SimpleLoader />;
  }

  if (small) {
    return table;
  }

  return (
    <>
      {UIModalFileGridReact}
      <Dialog
        maxWidth="lg"
        fullWidth={true}
        onClose={handleCloseEployee}
        open={openEployee}
      >
        <EmployeeCard
          id={employeeId}
          child_model={employeeModel}
          type="employees"
          onClose={handleCloseEployee}
        />
      </Dialog>
      <Dialog
        maxWidth="lg"
        fullWidth={true}
        onClose={handleCloseCustomer}
        open={openCustomer}
      >
        <CustomerCard id={customerId} onClose={handleCloseCustomer} />
      </Dialog>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        onClose={handleCloseLargeText}
        open={largeTextOpen}
        PaperProps={{ sx: { minHeight: "200px", p: 2 } }}
      >
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Button variant="outlined" onClick={handleCloseLargeText}>
            {t("Zavřít")}
          </Button>
        </Box>
        <Box>{largeText}</Box>
      </Dialog>

      {policy_action_tree.employees.includes("audit") && (
        <Popover
          anchorOrigin={{
            horizontal: "center",
            vertical: "top",
          }}
          anchorEl={document.querySelector("body")}
          onClose={() => {
            setShowAudit(false);
          }}
          open={showAudit}
          PaperProps={{
            sx: { width: "1250px" },
          }}
        >
          <Audit type={model} id={showAuditId} />
        </Popover>
      )}
      {/* <MultiFileModal
        data={multiFileData}
        onClose={() => setMultiFileData(null)}
        isOpen={multiFileData !== null}
      /> */}
      <DateModal
        isOpen={!!pendingChange}
        onClose={() => setPendingChange(null)}
        onSubmit={(date) => {
          postUpdate(pendingChange.data, date).then((newData) => {
            if (!newData) return;

            let row = gridApi?.getRowNode(pendingChange.e.node.id);
            if (!row) return;

            row.setData(newData);
          });
          setPendingChange(null);
        }}
      />
      <Notes
        open={noteOpen}
        id={noteId}
        model={model}
        onClose={() => {
          setNoteOpen(false);
        }}
      />
      <Helmet>
        <title>{helmet}</title>
      </Helmet>
      <Box
        sx={
          style ?? {
            backgroundColor: "background.default",
            height: "100%",
            py: 4,
          }
        }
      >
        <Container
          // maxWidth={settings.compact ? "xl" : false}
          style={{ maxWidth: "100%", height: "100%" }}
        >
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item xs={3}>
              <Typography color="textPrimary" variant="h5">
                {t(title)}
              </Typography>
            </Grid>
            <Grid item>
              <Box sx={{ mr: 2, display: "flex", alignItems: "center" }}>
                {needTemplate && (
                  <Box sx={{ mt: 1 }}>
                    <Autocomplete
                      size={"small"}
                      sx={{ width: "200px", mx: 1 }}
                      value={
                        templateName.find(
                          (t) => t.value === selectedTemplate
                        ) ?? ""
                      }
                      disableClearable
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Šablóna")}
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment:
                              selectedTemplate !== "default"
                                ? addRemoveButtonToEndAdornment(
                                    addSaveButtonToEndAdornment(
                                      params.InputProps.endAdornment
                                    )
                                  )
                                : params.InputProps.endAdornment,
                          }}
                        />
                      )}
                      options={templateName ?? []}
                      onChange={(e, newVal) => {
                        if (typeof newVal === "string") {
                          let value = newVal
                            .toLowerCase()
                            .trim()
                            .replace(" ", "_");
                          console.log("Value", value);
                          if (
                            newVal &&
                            !templateName.find(
                              (template) => template.value === value
                            )
                          ) {
                            setTemplate({
                              value: value,
                              label: newVal,
                              data: columnState,
                            });
                            setTemplateName([
                              ...templateName,
                              {
                                value: value,
                                label: newVal,
                              },
                            ]);
                            saveTemplate({
                              value: value,
                              label: newVal,
                              data: columnState,
                            });
                          }
                          setSelectedTemplate(value);
                        } else {
                          setSelectedTemplate(newVal.value);
                          setTemplateLable(newVal.label);
                        }
                      }}
                    />
                  </Box>
                )}
                {ready && body}
                {ready && !withoutExcel && (
                  <Button
                    sx={{ ml: 2, px: 2, height: "100%" }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      gridApi.exportDataAsExcel({
                        processCellCallback: (params) => {
                          console.log(params.value, params);

                          if (
                            params.value &&
                            typeof params.value == "string" &&
                            /^\-?[0-9]+\,[0-9]{3}\.[0-9]{2}$/.test(
                              params.value
                            ) == true
                          ) {
                            params.value = params.value.substring(
                              0,
                              params.value.length - 3
                            );

                            params.value = params.value.replace(",", "");
                          } else if (
                            params.value &&
                            typeof params.value == "string" &&
                            params.value == "-"
                          ) {
                            params.value = "0";
                          }

                          const colDef = params.column.getColDef();

                          if (colDef.valueFormatter) {
                            const valueFormatterParams = {
                              ...params,
                              data: params.node.data,
                              node: params.node,
                              colDef: params.column.getColDef(),
                            };

                            return colDef.valueFormatter(valueFormatterParams);
                          }

                          return params.value;
                        },
                      });
                    }}
                  >
                    Excel
                  </Button>
                )}
                {!withoutFilterReset && (
                  <Button
                    sx={{ ml: 2, px: 2, height: "100%" }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      gridApi.setFilterModel(null);
                      gridApi.onFilterChanged();
                      filter.clear();
                    }}
                  >
                    <FilterAltOffIcon />
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{ mt: 3 }}
            style={{ width: "100%", height: "calc(100% - 50px)" }}
          >
            {!ready && <LinearProgress />}
            {table}
          </Box>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t("Potvrdit odstranění")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t(
                  "Jste si jisti, že chcete odstranit tuto šablóna a všechny šablony s ní spojené?"
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                {t("Ne")}
              </Button>
              <Button onClick={deleteTemplate} color="primary" autoFocus>
                {t("Ano")}
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </Box>
    </>
  );
});
