import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { addMinutes } from "date-fns";
import * as Yup from "yup";
import { Formik } from "formik";
import MobileDateTimePicker from "@material-ui/lab/MobileDateTimePicker";
import HttpService from "../../../services/HttpService.js";
import UrlService from "../../../services/UrlService.js";
import { updateEmployeeList } from "../../../slices/vacancies";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import RequiredFieldsForm from "./RequiredFieldsForm.js";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  Switch,
  TextField,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  Autocomplete,
  Tooltip,
} from "@material-ui/core";
import TrashIcon from "../../../icons/Trash";
import { experimentalStyled } from "@material-ui/core/styles";
import {
  createEvent,
  deleteEvent,
  updateEvent,
} from "../../../slices/calendar";
import { useDispatch, useSelector } from "../../../store";
import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../contexts/DataContext";
import SimpleLoader from "../../SimpleLoader";
import { useTranslation } from "react-i18next";

const iconsMap = {
  0: ClearIcon,
  1: ArrowForwardIcon,
  2: ArrowForwardIcon,
  3: BusinessCenterIcon,
  4: ClearIcon,
  5: ArrowForwardIcon,
  6: BusinessCenterIcon,
  7: ClearIcon,
  8: ClearIcon,
  9: DoubleArrowIcon,
  10: ClearIcon,
  11: ClearIcon,
  12: ClearIcon,
  13: ClearIcon,
  14: ClearIcon,
  15: ClearIcon,
  16: ClearIcon,
};

const CircleAvatar = experimentalStyled("div")({
  width: 40,
  height: 40,
  borderRadius: "50%",
  backgroundColor: "#ccc",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: 8,
  color: "#fff",
  fontWeight: "bold",
});

const getInitialValues = (event, range) => {
  if (event) {
    return {
      allDay: event.allDay || false,
      color: event.color || "",
      description: event.description || "",
      end: event.end ? new Date(event.end) : addMinutes(new Date(), 30),
      start: event.start ? new Date(event.start) : new Date(),
      interaction: event.interaction || "",
      submit: null,
      next_interaction: event.next_interaction || "",
      next_end: event.next_end ? new Date(event.next_end) : new Date(),
      next_start: event.next_start ? new Date(event.next_start) : new Date(),
      next_description: event.next_description || "",
      result: event.result || "",
      employee: "",
      vacancy: "",
    };
  }

  if (range) {
    return {
      allDay: false,
      color: "",
      description: "",
      end: new Date(range.end),
      start: new Date(range.start),
      interaction: "",
      submit: null,
      next_interaction: "",
      next_end: addMinutes(new Date(), 30),
      next_start: new Date(),
      next_description: "",
      result: "",
      employee: "",
      vacancy: "",
    };
  }

  return {
    allDay: false,
    color: "",
    description: "",
    end: addMinutes(new Date(), 30),
    start: new Date(),
    interaction: "",
    submit: null,
    next_interaction: "",
    next_end: addMinutes(new Date(), 30),
    next_start: new Date(),
    next_description: "",
    result: "",
    employee: "",
    vacancy: "",
  };
};

const CalendarEventForm = (props) => {
  const {
    event,
    onAddComplete,
    onCancel,
    onDeleteComplete,
    onEditComplete,
    range,
    employeeId,
    employeeName,
    employeeSurname,
  } = props;
  const { t } = useTranslation();

  const policy_action_tree = JSON.parse(
    localStorage.getItem("policy_action_tree")
  );

  const dispatch = useDispatch();
  const { data: contextData, isLoading } = useContext(DataContext);
  const { calendar_actions, calendar_results, vacancies } = contextData;
  const [people, setPeople] = useState([]);
  const [formFields, setFormFields] = useState(null);
  const [formValues, setFormValues] = useState({});
  const [interactionsValues, setInteractionsValues] = useState(null);
  const [newOwnerId, setNewOwnerId] = useState(event?.created_by || "");

  const thp_users = contextData.thp_users.filter((user) => {
    let today = new Date();
    let ukonceni_pp_date = user.ukonceni_pp ? new Date(user.ukonceni_pp) : null;
    let smlouva_do_date = user.smlouva_do ? new Date(user.smlouva_do) : null;

    return (
      (!ukonceni_pp_date || ukonceni_pp_date > today) &&
      (!smlouva_do_date || smlouva_do_date > today)
    );
  });

  const canChangeOwner = policy_action_tree.interactions.includes("transfer");

  console.log({ event });
  console.log({ canChangeOwner });
  console.log({ thp_users });

  const technicalResults = [13, 14, 15, 16, 9];

  const filteredResults = calendar_results.filter(
    (res) => !technicalResults.includes(res.id)
  );

  const results = policy_action_tree.vacancies.includes("add_man")
    ? filteredResults
    : filteredResults.filter((res) => res.id != 6);

  const resultsOptions = event?.result ? calendar_results : results;

  const options = people.sort((a, b) =>
    a.name > b.name ? 1 : b.name > a.name ? -1 : 0
  );

  function sortAndFormatVacancies(vacancies) {
    vacancies.sort((a, b) => {
      const companyComparison = a.customer_name.localeCompare(b.customer_name);

      if (companyComparison === 0) {
        return new Date(a.interview_date) - new Date(b.interview_date);
      }
      return companyComparison;
    });

    const formattedVacancies = vacancies.map((vacancy) => {
      const dateParts = vacancy.interview_date.split("-");
      const formattedDate = `${dateParts[2]}.${dateParts[1]}`;
      return {
        id: vacancy.id,
        name: `${vacancy.customer_name}, ${formattedDate}, ${vacancy.vacancy_name}`,
      };
    });

    return formattedVacancies;
  }

  const sortedVacancies = sortAndFormatVacancies(vacancies);

  const handleDelete = async () => {
    try {
      dispatch(deleteEvent(event.id));

      onDeleteComplete?.();
    } catch (err) {
      console.error(err);
    }
  };
  const handleSearch = () => {
    HttpService.get(UrlService.apiDomain() + `api/get-users-by-nabor`)
      .then((res) => {
        setPeople(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    handleSearch();
  }, []);

  const handleEmployeeAdd = async (id) => {
    try {
      const res = await HttpService.post(
        UrlService.apiDomain() + "api/add-employee-to-vacancy",
        {
          employee_id: event.employee_id,
          vacancy_id: id,
          state: true,
        }
      );
      if (res.status != 200) {
        toast.error(t("Něco se pokazilo!"));
        return;
      }
      toast.success(t("Osoba přidána"));
    } catch (err) {
      console.error(err);
      toast.error(t("Něco se pokazilo!"));
    }
  };
  const handleOwnerChange = async () => {
    try {
      const res = await HttpService.post(
        UrlService.apiDomain() + "api/calendar-transfer",
        {
          employeeId: newOwnerId,
          eventId: event.id,
        }
      );
      if (res.status != 200) {
        toast.error(t("Něco se pokazilo!"));
        return;
      }
      toast.success(t("Odpovědný se změnil"));
    } catch (err) {
      console.error(err);
      toast.error(t("Něco se pokazilo!"));
    }
  };

  const renderAvatar = () => {
    const initials =
      event.nabor &&
      event.nabor
        .split(" ")
        .map((name) => name.charAt(0))
        .join("");
    return <CircleAvatar>{initials}</CircleAvatar>;
  };

  const onAdd = (data) => {
    dispatch(updateEvent({ eventId: event.id, update: data }));
  };

  const disabled = !!event?.result;

  const getTitle = (employee_id, interaction) => {
    if (employeeId) {
      if (interaction == 1) {
        return `Zavolejte ${employeeName} ${employeeSurname}`;
      } else return `Setkání s ${employeeName} ${employeeSurname}`;
    }

    if (!event && !employeeId) {
      const name = people.find((item) => item.id == employee_id).name;
      const surname = people.find((item) => item.id == employee_id).surname;
      if (interaction == 1) {
        return `Zavolejte ${name} ${surname}`;
      } else return `Setkání s ${name} ${surname}`;
    } else {
      if (interaction == 1) {
        return `Zavolejte ${employee_id}`;
      } else return `Setkání s ${employee_id}`;
    }
  };

  if (isLoading) {
    return <SimpleLoader />;
  }
  return (
    <>
      <Formik
        initialValues={getInitialValues(event, range)}
        validationSchema={Yup.object().shape({
          // allDay: Yup.bool(),
          description: Yup.string().max(5000),
          next_description: Yup.string().max(5000),
          end: Yup.date().when(
            "start",
            (start, schema) =>
              start &&
              schema.min(
                start,
                t("Datum ukončení musí být pozdější než datum zahájení")
              )
          ),
          start: Yup.date(),
          next_start: Yup.date(),
          next_end: Yup.date().when(
            "next_start",
            (next_start, schema) =>
              next_start &&
              schema.min(
                next_start,
                t("Datum ukončení musí být pozdější než datum zahájení")
              )
          ),
          interaction: Yup.string().max(255).required(t("Interakce povinna")),
          result: Yup.string(),
          vacancy: Yup.string(),
          employee: Yup.string().test(
            "required",
            t("Přidejte osobu"),
            function (value) {
              if (event == null && !employeeId) {
                return value !== undefined && value !== "";
              }
              return true;
            }
          ),
          next_interaction: Yup.string().when("result", {
            is: (value) => value == 1 || value == 2,
            then: Yup.string().max(255).required(t("Určete další akci")),
          }),
        })}
        onSubmit={async (
          values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          try {
            const data = {
              // allDay: values.allDay,
              description: values.description,
              vacancy: values.vacancy,
              end: values.end,
              start: values.start,
              interaction: values.interaction,
              next_interaction: values.next_interaction,
              next_start: values.next_start,
              next_end: values.next_end,
              next_description: values.next_description,
              employee: employeeId || values.employee || event.employee,
              color: values.result
                ? "#bdc2cb"
                : values.interaction == 1
                ? "#eee572"
                : "#43a048",
              title: event
                ? getTitle(event.employee, values.interaction)
                : getTitle(values.employee, values.interaction),
              result: values.result,
              next_color: values.next_interaction == 1 ? "#eee572" : "#43a048",
              next_title:
                event && getTitle(event.employee, values.next_interaction),
            };

            if (event) {
              await dispatch(updateEvent({ eventId: event.id, update: data }))
                .unwrap()
                .then((response) => {
                  values.vacancy && handleEmployeeAdd(values.vacancy);
                  newOwnerId && handleOwnerChange();
                  // Обработать успешный респонс
                  resetForm();
                  setStatus({ success: true });
                  setSubmitting(false);

                  if (!event && onAddComplete) {
                    onAddComplete();
                  }

                  if (event && onEditComplete) {
                    onEditComplete();
                  }
                })
                .catch((error) => {
                  setFormFields(error.fields);
                  setFormValues(error.data);
                  setInteractionsValues(data);
                  console.log({ error });
                  return;
                });
            } else {
              await dispatch(createEvent(data));
              resetForm();
              setStatus({ success: true });
              setSubmitting(false);

              if (!event && onAddComplete) {
                onAddComplete();
              }

              if (event && onEditComplete) {
                onEditComplete();
              }
            }
          } catch (err) {
            console.error(err);
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box sx={{ p: 3 }}>
              <Typography align="center" color="textPrimary" variant="h5">
                {event ? t("Upravit událost") : t("Přidat událost")}
              </Typography>
            </Box>
            {event && (
              <Box
                sx={{
                  display: "inline-flex",
                  width: 1,
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: 3,
                }}
              >
                <Box>
                  <Typography align="left" color="textPrimary" variant="h6">
                    {event.employee}, {event.date_of_birth}, {event.citizenship}
                  </Typography>
                  <Typography
                    align="left"
                    color="textPrimary"
                    variant="subtitle1"
                  >
                    {t("Datum registrace")}: {event.registration}
                  </Typography>
                  <a
                    href={`tel:${event.phone}`}
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      variant="body1"
                      color="primary"
                      style={{ cursor: "pointer" }}
                    >
                      {event.phone}
                    </span>
                  </a>
                </Box>
                <Box>
                  <Tooltip title={event.nabor || ""}>{renderAvatar()}</Tooltip>
                </Box>
              </Box>
            )}

            <Typography align="center" color="textPrimary" variant="subtitle2">
              {t("Aktuální")}
            </Typography>
            <Divider />
            <Box sx={{ p: 3 }}>
              {!event && !employeeId && (
                <FormControl fullWidth>
                  <Autocomplete
                    disabled={disabled}
                    name={`employee`}
                    options={options}
                    getOptionLabel={(option) =>
                      `${option.name} ${option.surname}, ${option?.date_of_birth}, ${option?.code}`
                    }
                    onChange={(e, value) => {
                      setFieldValue(`employee`, value?.id);
                    }}
                    // onInputChange={(e, value) => handleSearch(value)}
                    onBlur={handleBlur}
                    value={
                      people.find((person) => person.id === values.employee) ||
                      null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("Přidat osobu")}
                        error={!!(touched.employee && errors.employee)}
                        helperText={touched.employee && errors.employee}
                      />
                    )}
                  />
                </FormControl>
              )}
              <FormControl
                sx={{ mt: 2 }}
                error={Boolean(touched.interaction && errors.interaction)}
                fullWidth
                variant="outlined"
              >
                <InputLabel id="interaction">{t("Interakce")}</InputLabel>
                <Select
                  disabled={disabled}
                  id="interaction"
                  label={t("Interakce")}
                  name="interaction"
                  value={values.interaction}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {calendar_actions.map((action) => (
                    <MenuItem key={action.id} value={action.id}>
                      {t(action.name)}
                    </MenuItem>
                  ))}
                </Select>
                {touched.interaction && errors.interaction && (
                  <FormHelperText>{errors.interaction}</FormHelperText>
                )}
              </FormControl>
              <Box sx={{ mt: 2 }}>
                <TextField
                  disabled={disabled}
                  error={Boolean(touched.description && errors.description)}
                  fullWidth
                  helperText={touched.description && errors.description}
                  label={t("Popis")}
                  name="description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description}
                  variant="outlined"
                />
              </Box>
              <Box
                sx={{
                  display: "inline-flex",
                  width: 1,
                  gap: 2,
                  mt: 2,
                }}
              >
                <Box sx={{ width: "50%" }}>
                  <MobileDateTimePicker
                    disabled={disabled}
                    inputFormat="dd.MM.yyyy, HH:mm"
                    ampm={false}
                    label={t("Zahájení")}
                    onChange={(date) => {
                      setFieldValue("start", date);
                      setFieldValue("end", addMinutes(date, 30));
                    }}
                    renderInput={(inputProps) => (
                      <TextField fullWidth variant="outlined" {...inputProps} />
                    )}
                    value={values.start}
                  />
                </Box>
                <Box sx={{ width: "50%" }}>
                  <MobileDateTimePicker
                    disabled={disabled}
                    inputFormat="dd.MM.yyyy, HH:mm"
                    ampm={false}
                    label={t("Ukončení")}
                    onChange={(date) => setFieldValue("end", date)}
                    renderInput={(inputProps) => (
                      <TextField fullWidth variant="outlined" {...inputProps} />
                    )}
                    value={values.end}
                  />
                </Box>
              </Box>
              {Boolean(touched.end && errors.end) && (
                <Box sx={{ mt: 2 }}>
                  <FormHelperText error>{errors.end}</FormHelperText>
                </Box>
              )}
              {event && (
                <FormControl
                  sx={{ mt: 2 }}
                  error={Boolean(touched.result && errors.result)}
                  fullWidth
                  variant="outlined"
                >
                  <InputLabel id="result">{t("Výsledek")}</InputLabel>
                  <Select
                    disabled={disabled}
                    id="result"
                    label={t("Výsledek")}
                    name="result"
                    value={values.result}
                    onChange={(e, v) => {
                      handleChange(e, v);
                      setFormFields(null);
                    }}
                    onBlur={handleBlur}
                  >
                    {resultsOptions.map((res) => {
                      const Icon = iconsMap[res.id];
                      return (
                        <MenuItem key={res.id} value={res.id}>
                          <Box
                            sx={{
                              display: "inline-flex",
                              width: 1,
                              justifyContent: "space-between",
                            }}
                          >
                            {t(res.name)}
                            <Icon fontSize="small" />
                          </Box>
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {touched.result && errors.result && (
                    <FormHelperText>{errors.result}</FormHelperText>
                  )}
                </FormControl>
              )}
              {canChangeOwner && event && (
                <FormControl sx={{ mt: 2 }} fullWidth variant="outlined">
                  <InputLabel id="newOwner">
                    {t("Změna odpovědné osoby")}
                  </InputLabel>
                  <Select
                    id="newOwner"
                    label={t("Změna odpovědné osoby")}
                    name="interaction"
                    value={newOwnerId}
                    onChange={(event, value) => {
                      setNewOwnerId(value.props.value);
                    }}
                  >
                    {thp_users.map((user) => (
                      <MenuItem key={user.id} value={user.id}>
                        {t(user.name)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {values.result == 6 && event.color !== "#bdc2cb" && (
                <FormControl
                  sx={{ mt: 2 }}
                  error={Boolean(touched.vacancy && errors.vacancy)}
                  fullWidth
                  variant="outlined"
                >
                  <InputLabel id="vacancy">{t("Přidat do...")}</InputLabel>
                  <Select
                    disabled={disabled}
                    id="vacancy"
                    label={t("Přidat do...")}
                    name="vacancy"
                    value={values.vacancy}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    {sortedVacancies.map((res) => (
                      <MenuItem key={res.id} value={res.id}>
                        {res.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.vacancy && errors.vacancy && (
                    <FormHelperText>{errors.vacancy}</FormHelperText>
                  )}
                </FormControl>
              )}
              {(values.result == 1 || values.result == 2) &&
                event.color !== "#bdc2cb" && (
                  <>
                    <Typography
                      sx={{ mt: 2 }}
                      align="center"
                      color="textPrimary"
                      variant="subtitle2"
                    >
                      {t("Další akce")}
                    </Typography>
                    <Divider />
                    <FormControl
                      error={Boolean(
                        touched.next_interaction && errors.next_interaction
                      )}
                      fullWidth
                      variant="outlined"
                      sx={{ mt: 2 }}
                    >
                      <InputLabel id="next_interaction">
                        {t("Interakce")}
                      </InputLabel>
                      <Select
                        disabled={disabled}
                        id="next_interaction"
                        label={t("Interakce")}
                        name="next_interaction"
                        value={values.next_interaction}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        {calendar_actions.map((action) => (
                          <MenuItem key={action.id} value={action.id}>
                            {t(action.name)}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.next_interaction && errors.next_interaction && (
                        <FormHelperText>
                          {errors.next_interaction}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <Box sx={{ mt: 2 }}>
                      <TextField
                        disabled={disabled}
                        error={Boolean(
                          touched.next_description && errors.next_description
                        )}
                        fullWidth
                        helperText={
                          touched.next_description && errors.next_description
                        }
                        label={t("Popis")}
                        name="next_description"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.next_description}
                        variant="outlined"
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "inline-flex",
                        width: 1,
                        gap: 2,
                        mt: 2,
                      }}
                    >
                      <Box sx={{ width: "50%" }}>
                        <MobileDateTimePicker
                          disabled={disabled}
                          inputFormat="dd.MM.yyyy, HH:mm"
                          ampm={false}
                          label={t("Zahájení")}
                          onChange={(date) => {
                            setFieldValue("next_start", date);
                            setFieldValue("next_end", addMinutes(date, 30));
                          }}
                          renderInput={(inputProps) => (
                            <TextField
                              fullWidth
                              variant="outlined"
                              {...inputProps}
                            />
                          )}
                          value={values.next_start}
                        />
                      </Box>
                      <Box sx={{ width: "50%" }}>
                        <MobileDateTimePicker
                          disabled={disabled}
                          inputFormat="dd.MM.yyyy, HH:mm"
                          ampm={false}
                          label={t("Ukončení")}
                          onChange={(date) => setFieldValue("next_end", date)}
                          renderInput={(inputProps) => (
                            <TextField
                              fullWidth
                              variant="outlined"
                              {...inputProps}
                            />
                          )}
                          value={values.next_end}
                        />
                      </Box>
                    </Box>
                    {Boolean(touched.next_end && errors.next_end) && (
                      <Box sx={{ mt: 2 }}>
                        <FormHelperText error>{errors.next_end}</FormHelperText>
                      </Box>
                    )}
                  </>
                )}
              {/* <Box sx={{ mt: 2 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.allDay}
                      color="primary"
                      name="allDay"
                      onChange={handleChange}
                    />
                  }
                  label="All day"
                />
              </Box> */}
            </Box>
            <Divider />
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                p: 2,
              }}
            >
              {event && (
                <IconButton onClick={() => handleDelete()}>
                  <TrashIcon fontSize="small" />
                </IconButton>
              )}
              <Box sx={{ flexGrow: 1 }} />
              <Button color="primary" onClick={onCancel} variant="text">
                {t("Zrušení")}
              </Button>
              <Button
                color="primary"
                disabled={isSubmitting || !!formFields}
                sx={{ ml: 1 }}
                type="submit"
                variant="contained"
              >
                {t("Uložit")}
              </Button>
            </Box>
            {formFields && (
              <>
                <Box sx={{ textAlign: "center", mb: 2 }}>
                  {t("Vyplňte všechna povinná pole")}
                </Box>
                <Box sx={{ mb: 2 }}>
                  <RequiredFieldsForm
                    eventId={event.id}
                    onAdd={onAdd}
                    onClose={onAddComplete}
                    interactionsValues={interactionsValues}
                    noTemplates={true}
                    columns={formFields}
                    model="employees"
                    defaultValues={formValues}
                  />
                </Box>
              </>
            )}
          </form>
        )}
      </Formik>
    </>
  );
};

CalendarEventForm.propTypes = {
  event: PropTypes.object,
  onAddComplete: PropTypes.func,
  onCancel: PropTypes.func,
  onDeleteComplete: PropTypes.func,
  onEditComplete: PropTypes.func,
  range: PropTypes.object,
};

export default CalendarEventForm;
