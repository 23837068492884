import { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Chip,
  MenuItem,
  FormControl,
  Select,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import HttpService from "../../../services/HttpService.js";
import UrlService from "../../../services/UrlService.js";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import toast from "react-hot-toast";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SimpleLoader from "../../../components/SimpleLoader";
import EmailIcon from "@mui/icons-material/Email";
import useFilter from "../../../hooks/useFilter";

const testData = {
  attachments: [
    {
      id: 1,
      name: "Assa ablow.xlxs",
      path: "https://",
      customer: "Assa Abloy",
      log: [
        { name: "Denis Kuper", date: "25.08.2023" },
        { name: "Danycz DMS", date: "10.08.2023" },
      ],
    },
    {
      id: 1,
      name: "Daiken.xlxs",
      path: "https://",
      customer: "Daiken",
      log: [{ name: "Dima Kolpakov", date: "05.08.2023" }],
    },
  ],
  reward: [{ sum: "2000", date: "01.08.2023", employee: "Kiril Priluckoiy" }],
  visits: [
    {
      date: "01.08.2023",
      customer: "Assa Abloy inc",
      customer_id: "176",
      hours: 8,
    },
    {
      date: "02.08.2023",
      customer: "Assa Abloy inc",
      customer_id: "176",
      hours: 8,
    },
    {
      date: "03.08.2023",
      customer: "Assa Abloy inc",
      customer_id: "176",
      hours: 8,
    },
    {
      date: "04.08.2023",
      customer: "Assa Abloy inc",
      customer_id: "176",
      hours: 8,
    },
    {
      date: "05.08.2023",
      customer: "Assa Abloy inc",
      customer_id: "176",
      hours: 8,
    },
    {
      date: "08.08.2023",
      customer: "Assa Abloy inc",
      customer_id: "176",
      hours: "Ne rabotal",
    },
    {
      date: "09.08.2023",
      customer: "Assa Abloy inc",
      customer_id: "176",
      hours: 8,
    },
    {
      date: "10.08.2023",
      customer: "Assa Abloy inc",
      customer_id: "176",
      hours: 8,
    },
    {
      date: "11.08.2023",
      customer: "Assa Abloy inc",
      customer_id: "176",
      hours: 8,
    },
    {
      date: "12.08.2023",
      customer: "Assa Abloy inc",
      customer_id: "176",
      hours: 8,
    },
    {
      date: "13.08.2023",
      customer: "Assa Abloy inc",
      customer_id: "176",
      hours: 8,
    },
    {
      date: "15.08.2023",
      customer: "Assa Abloy inc",
      customer_id: "176",
      hours: 8,
    },
    {
      date: "16.08.2023",
      customer: "Assa Abloy inc",
      customer_id: "176",
      hours: 8,
    },
    {
      date: "17.08.2023",
      customer: "Assa Abloy inc",
      customer_id: "176",
      hours: 8,
    },
    {
      date: "18.08.2023",
      customer: "Assa Abloy inc",
      customer_id: "176",
      hours: 8,
    },
    {
      date: "23.08.2023",
      customer: "Assa Abloy inc",
      customer_id: "176",
      hours: 8,
    },
    {
      date: "24.08.2023",
      customer: "Assa Abloy inc",
      customer_id: "176",
      hours: 8,
    },
    { date: "25.08.2023", customer: "Daiken", customer_id: "320", hours: 7 },
    {
      date: "26.08.2023",
      customer: "Daiken",
      customer_id: "320",
      hours: "Bolel",
    },
    { date: "27.08.2023", customer: "Daiken", customer_id: "320", hours: 7 },
    { date: "28.08.2023", customer: "Daiken", customer_id: "320", hours: 7 },
    { date: "31.08.2023", customer: "Daiken", customer_id: "320", hours: 7 },
  ],
  allVisits: [
    {
      customer: "Assa Abloy inc",
      hours: "198",
      customer_id: "326",
    },
    {
      customer: "Daiken",
      hours: "56",
      customer_id: "130",
    },
  ],
  accommodationToMonth: [
    {
      hostel: "Svihovska 12",
      room: "12",
      price: "250",
      from: "01.08.2023",
      forCash: "Ano",
      to: "31.08.2023",
      days: "31",
      sum: "5036",
    },
  ],
  accommodationToNow: [
    {
      hostel: "Svihovska 12",
      forCash: "Ne",
      room: "12",
      price: "250",
      from: "01.08.2023",
      to: "31.08.2023",
      days: "31",
      sum: "5036",
    },
  ],
  paidDays: {
    month: {
      days: 5,
      sum: 500,
    },
    now: {
      days: 2,
      sum: 200,
    },
  },
  rests: [
    {
      rest: "bla bla",
      comment: "comment",
      customer: "Assa Abloy",
      sum: "1000",
    },
    {
      rest: "bla",
      comment: "comment2",
      customer: "Assa Abloy",
      sum: "500",
    },
  ],
  payedRests: [
    {
      rest: "bla bla",
      comment: "comment",
      customer: "Assa Abloy",
      sum: "500",
    },
  ],
  finesCoordinator: [
    {
      fines: "Ukral edy y soseda",
      employee: "Vasya Belo",
      date: "10.08.2023",
      sum: "500",
    },
  ],
  finesCustomer: [
    {
      fines: "Ukral instryment",
      employee: "Serhey Kyznecov",
      date: "14.08.2023",
      sum: "2000",
    },
  ],
  downpayments: [
    {
      employee: "Galyna Belo",
      date: "14.08.2023",
      sum: "1000",
      customer: "Assa Abloy",
    },
  ],
  commission: [
    {
      customer: "Daiken",
      role: "Koordinator",
      plan: 1000,
      hours_customer: 900,
      days: 28,
      per_hour: 0.3,
      commission: 4000,
    },
  ],
  totalSum: "-2570",
};

const IndividualSalaryReport = ({
  propsMonth,
  propsYear,
  uzivatel,
  onClose,
  id,
  name,
  employee_email,
}) => {
  const filter = useFilter();
  const { t } = useTranslation();
  const at = localStorage.getItem("accessToken");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [attachments, setAttachments] = useState([]);
  const [selectedValue, setSelectedValue] = useState("all");
  const fileInputRef = useRef(null);
  const [openEmail, setOpenEmail] = useState(false);
  const [email, setEmail] = useState(employee_email ?? "");
  const [attachmentId, setAttachmentId] = useState("");
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();

  const previousMonth = currentMonth === 1 ? 12 : currentMonth - 1;
  const previousYear = currentMonth === 1 ? currentYear - 1 : currentYear;

  const [month, setMonth] = useState(propsMonth || previousMonth);
  const [year, setYear] = useState(propsYear || previousYear);

  useEffect(() => {
    if (!propsMonth && !propsYear) {
      let filterYear = filter.getYearFilter();
      let filterMonth = filter.getMonthFilter() && filter.getMonthFilter() - 0;

      if (filterYear) setYear(filterYear);
      if (filterMonth) setMonth(filterMonth);
    }
  }, [filter, propsMonth, propsYear]);

  const monthNames = [
    "Leden",
    "Únor",
    "Březen",
    "Duben",
    "Květen",
    "Červen",
    "Červenec",
    "Srpen",
    "Září",
    "Říjen",
    "Listopad",
    "Prosinec",
  ];
  const month_list = [];

  for (let i = 1; i <= 12; i++) {
    month_list.push(
      <MenuItem key={i} value={i}>
        {t(monthNames[i - 1])}
      </MenuItem>
    );
  }

  const year_list = [];

  for (let i = -5; i <= 1; i++) {
    var d = new Date();
    const y = d.getYear() + i + 1900;
    year_list.push(
      <MenuItem key={y} value={y}>
        {y}
      </MenuItem>
    );
  }

  const policy_actions = JSON.parse(
    localStorage.getItem("policy_action_tree")
  ).salaries;

  const handleOpenEmail = () => {
    setOpenEmail(true);
  };

  const handleCloseEmail = () => {
    setEmail(employee_email ?? "");
    setOpenEmail(false);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSendEmail = () => {
    HttpService.post(
      UrlService.apiDomain() + `api/send-salary-email/${attachmentId}`,
      { email }
    )
      .then((res) => {
        if (res.status != 200) {
          toast.error("Něco se pokazilo!");
          handleCloseEmail();
          return;
        }
        toast.success("Email odeslán");
        handleCloseEmail();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const customersHours = {};

  data.visits &&
    data.visits.forEach((visit) => {
      if (typeof visit.hours === "number" && !isNaN(visit.hours)) {
        if (!customersHours[visit.customer_id]) {
          customersHours[visit.customer_id] = {
            customer: visit.customer,
            hours: 0,
          };
        }
        customersHours[visit.customer_id].hours += visit.hours;
      }
    });

  const allHours = Object.values(customersHours);

  const gtr = () => {
    setLoading(true);
    HttpService.get(
      UrlService.apiDomain() +
        `api/salary-report/${year}/${month}/${uzivatel}/${id}`
    )
      .then((res) => {
        if (res.status != 200) {
          setLoading(false);
          alert("No data");
          return;
        }
        setData(res.data);
        setAttachments(res.data.attachments);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    gtr();
  }, [year, month]);

  const handleSelectChange = (e) => {
    setSelectedValue(e.target.value);
    if (e.target.value !== "all") {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Загрузка файла на сервер
      uploadFileToServer(file);
    }
  };

  const uploadFileToServer = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await HttpService.post(
        UrlService.apiDomain() +
          `api/add-salary-attachment/${year}/${month}/${id}/${selectedValue}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + at,
          },
        }
      );
      if (response.status !== 200) {
        toast.error("Něco se pokazilo!");
        return;
      }
      setSelectedValue("all");
      setAttachments(response.data);
      toast.success("Záznam obnoven!");

      // Обработка успешной загрузки, если необходимо
    } catch (error) {
      console.log(error);
    }
  };

  const deleteAtt = async (id) => {
    try {
      const response = await HttpService.delete(
        UrlService.apiDomain() + `api/delete-salary-attachment/${id}`
      );
      if (response.status !== 200) {
        toast.error("Něco se pokazilo!");
        return;
      }
      toast.success("Soubor smazán");
      setAttachments((prev) => prev.filter((at) => at.id != id));
    } catch (error) {
      console.log(error);
    }
  };

  const downloadAttachment = async (documentUrl, id, name) => {
    const link = document.createElement("a");
    link.href = documentUrl;
    link.setAttribute("download", name);
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    try {
      const response = await HttpService.get(
        UrlService.apiDomain() + `api/download-salary-attachment/${id}`
      );
      if (response.status !== 200) {
        toast.error("Něco se pokazilo!");
        return;
      }
      toast.success("Soubor stažen");
      // setAttachments(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    height: "90%",
  };
  return (
    <div style={style}>
      <Container
        maxWidth="90%"
        style={{
          width: "100%",
          height: "100%",
          background: "#f4f5f7",
          borderRadius: "20px",
          paddingBottom: 40,
        }}
      >
        <Dialog
          open={openEmail}
          onClose={handleCloseEmail}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {t("Odesílání pasku na e-mail")}
          </DialogTitle>
          <DialogContent>
            <TextField
              label={t("Zadejte e-maily oddělený čárkami")}
              name="email"
              value={email}
              onChange={handleEmailChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEmail} color="primary">
              {t("Zrušení")}
            </Button>
            <Button color="primary" autoFocus onClick={handleSendEmail}>
              {t("Potvrdit")}
            </Button>
          </DialogActions>
        </Dialog>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Box
              sx={{
                display: "flex",
                gap: 3,
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Typography color="textPrimary" variant="h5">
                {`${t("Individuální report")} ${name}, za ${month}.${year}`}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <Select
                  sx={{ m: 1 }}
                  size="small"
                  onChange={(event, value) => {
                    setMonth(value.props.value);
                  }}
                  value={month}
                  id="month_select"
                >
                  {month_list}
                </Select>
                <Select
                  sx={{ m: 1 }}
                  size="small"
                  onChange={(event, value) => {
                    setYear(value.props.value);
                  }}
                  value={year}
                  id="year_select"
                >
                  {year_list}
                </Select>
              </Grid>
              {onClose && (
                <Grid item>
                  <Button variant="outlined" onClick={onClose}>
                    {t("Zavřít")}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        {loading ? (
          <SimpleLoader />
        ) : (
          <>
            <Box
              sx={{ mt: 3 }}
              style={{
                width: "100%",
                height: "calc(100% - 100px)",
                display: "flex",
                flexWrap: "wrap",
                gap: "2rem",
                overflow: "auto",
              }}
            >
              {data.visits.length > 0 && (
                <Box
                  sx={{
                    flex: "1 1 20%",
                    maxHeight: "100%",
                  }}
                >
                  <Paper sx={{ overflow: "auto", maxHeight: "100%" }}>
                    <Typography
                      sx={{ backgroundColor: "#5866d2", color: "white" }}
                      align="center"
                      variant="h6"
                    >
                      {t("Přehled docházky")}
                    </Typography>
                    <TableContainer sx={{ maxHeight: "100%" }}>
                      <Table size="small" aria-label="simple table">
                        <caption style={{ padding: "5px 5px" }}>
                          {allHours.map(({ customer, hours }) => {
                            return (
                              <p
                                key={customer}
                                style={{
                                  padding: "0",
                                  margin: "0 0 5px 0",
                                  fontWeight: "700",
                                }}
                              >
                                {t("Celkem u")} {customer}: {+hours.toFixed(2)}{" "}
                                {t("hod.")}
                              </p>
                            );
                          })}
                        </caption>
                        <TableHead>
                          <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell align="right">{t("Datum")}</TableCell>
                            <TableCell align="right">{t("Uživatel")}</TableCell>
                            <TableCell align="right">{t("Docházka")}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.visits.map(
                            ({ date, customer, hours }, index) => (
                              <TableRow
                                key={index}
                                // sx={{
                                //   "&:last-child td, &:last-child th": { border: 0 },
                                // }}
                              >
                                <TableCell component="th" scope="row">
                                  {index + 1}
                                </TableCell>
                                <TableCell align="right">{date}</TableCell>
                                <TableCell align="right">{customer}</TableCell>
                                <TableCell align="right">{hours}</TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Box>
              )}
              {(data.accommodationToMonth.length > 0 ||
                data.accommodationToNow.length > 0 ||
                data.rests.length > 0 ||
                data.payedRests.length > 0) && (
                <Box
                  sx={{
                    flex: "1 1 40%",
                    maxHeight: "100%",
                  }}
                >
                  <Paper sx={{ overflow: "auto", maxHeight: "100%" }}>
                    {(data.accommodationToMonth.length > 0 ||
                      data.accommodationToNow.length > 0) && (
                      <Typography
                        sx={{ backgroundColor: "#5866d2", color: "white" }}
                        align="center"
                        variant="h6"
                      >
                        {t("Ubytování")}
                      </Typography>
                    )}
                    {data.accommodationToMonth.length > 0 && (
                      <>
                        <Typography sx={{ padding: "0 10px" }} variant="h6">
                          {t("Ubytování do konce měsíce")}
                        </Typography>
                        <TableContainer
                          sx={{ maxHeight: "100%", marginBottom: "30px" }}
                        >
                          <Table size="small" aria-label="simple table">
                            <caption
                              style={{ padding: "5px 5px", fontWeight: "700" }}
                            >
                              {t(
                                "Ubytování po dobu absencí, nemocí, karantény:"
                              )}{" "}
                              {data.paidDays.month.days} {t("dnů")},{" "}
                              {data.paidDays.month.sum} - Kč
                            </caption>
                            <TableHead>
                              <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell align="right">
                                  {t("Ubytovna")}
                                </TableCell>
                                <TableCell align="right">
                                  {t("Pokoj")}
                                </TableCell>
                                <TableCell align="right">
                                  {t("Za hotovost")}
                                </TableCell>
                                <TableCell align="right">
                                  {t("Cena za den")}
                                </TableCell>
                                <TableCell align="right">{t("Od")}</TableCell>
                                <TableCell align="right">{t("Do")}</TableCell>
                                <TableCell align="right">
                                  {t("Počet dnů")}
                                </TableCell>
                                <TableCell align="right">
                                  {t("Celkem")}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data.accommodationToMonth.map(
                                (
                                  {
                                    hostel,
                                    room,
                                    price,
                                    from,
                                    forCash,
                                    to,
                                    days,
                                    sum,
                                  },
                                  index
                                ) => (
                                  <TableRow
                                    key={index}
                                    // sx={{
                                    //   "&:last-child td, &:last-child th": { border: 0 },
                                    // }}
                                  >
                                    <TableCell component="th" scope="row">
                                      {index + 1}
                                    </TableCell>
                                    <TableCell align="right">
                                      {hostel}
                                    </TableCell>
                                    <TableCell align="right">{room}</TableCell>
                                    <TableCell align="right">
                                      {forCash}
                                    </TableCell>
                                    <TableCell align="right">{price}</TableCell>
                                    <TableCell align="right">{from}</TableCell>
                                    <TableCell align="right">{to}</TableCell>
                                    <TableCell align="right">{days}</TableCell>
                                    <TableCell align="right">{sum}</TableCell>
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>
                    )}
                    {data.accommodationToNow.length > 0 && (
                      <>
                        <Typography sx={{ padding: "0 10px" }} variant="h6">
                          {t("Ubytování ke dnešnímu dni")}
                        </Typography>
                        <TableContainer
                          sx={{ maxHeight: "100%", marginBottom: "30px" }}
                        >
                          <Table size="small" aria-label="simple table">
                            <caption
                              style={{ padding: "5px 5px", fontWeight: "700" }}
                            >
                              {t(
                                "Ubytování po dobu absencí, nemocí, karantény:"
                              )}{" "}
                              {data.paidDays.now.days} {t("dnů")},{" "}
                              {data.paidDays.now.sum} - Kč
                            </caption>
                            <TableHead>
                              <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell align="right">
                                  {t("Ubytovna")}
                                </TableCell>
                                <TableCell align="right">
                                  {t("Pokoj")}
                                </TableCell>
                                <TableCell align="right">
                                  {t("Za hotovost")}
                                </TableCell>
                                <TableCell align="right">
                                  {t("Cena za den")}
                                </TableCell>
                                <TableCell align="right">{t("Od")}</TableCell>
                                <TableCell align="right">{t("Do")}</TableCell>
                                <TableCell align="right">
                                  {t("Počet dnů")}
                                </TableCell>
                                <TableCell align="right">
                                  {t("Celkem")}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data.accommodationToNow.map(
                                (
                                  {
                                    hostel,
                                    room,
                                    price,
                                    from,
                                    forCash,
                                    to,
                                    days,
                                    sum,
                                  },
                                  index
                                ) => (
                                  <TableRow
                                    key={index}
                                    // sx={{
                                    //   "&:last-child td, &:last-child th": { border: 0 },
                                    // }}
                                  >
                                    <TableCell component="th" scope="row">
                                      {index + 1}
                                    </TableCell>
                                    <TableCell align="right">
                                      {hostel}
                                    </TableCell>
                                    <TableCell align="right">{room}</TableCell>
                                    <TableCell align="right">
                                      {forCash}
                                    </TableCell>
                                    <TableCell align="right">{price}</TableCell>
                                    <TableCell align="right">{from}</TableCell>
                                    <TableCell align="right">{to}</TableCell>
                                    <TableCell align="right">{days}</TableCell>
                                    <TableCell align="right">{sum}</TableCell>
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>
                    )}
                    {(data.rests.length > 0 ||
                      data.payedRests.length > 0 ||
                      data.medicalCheckups.length > 0) && (
                      <Typography
                        sx={{ backgroundColor: "#5866d2", color: "white" }}
                        align="center"
                        variant="h6"
                      >
                        {t("Resty")}
                      </Typography>
                    )}
                    {data.payedRests.length > 0 && (
                      <>
                        <Typography sx={{ padding: "0 10px" }} variant="h6">
                          {t("Resty od koordinátora ztržené ze mzdy v obdobi")}{" "}
                          {month}/{year}
                        </Typography>
                        <TableContainer
                          sx={{ maxHeight: "100%", marginBottom: "30px" }}
                        >
                          <Table size="small" aria-label="simple table">
                            <caption
                              style={{ padding: "5px 5px", fontWeight: "700" }}
                            >
                              {t("Celkem")}{" "}
                              {data.payedRests
                                .reduce(
                                  (acc, rest) => acc + parseFloat(rest.sum),
                                  0
                                )
                                .toFixed(2)}{" "}
                              - Kč
                            </caption>
                            <TableHead>
                              <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell align="right">{t("Rest")}</TableCell>
                                <TableCell align="right">
                                  {t("Částka")}
                                </TableCell>
                                <TableCell align="right">
                                  {t("Komentář")}
                                </TableCell>
                                <TableCell align="right">
                                  {t("Uživatel")}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data.payedRests.map(
                                ({ rest, comment, customer, sum }, index) => (
                                  <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                      {index + 1}
                                    </TableCell>
                                    <TableCell align="right">{rest}</TableCell>
                                    <TableCell align="right">{sum}</TableCell>
                                    <TableCell align="right">
                                      {comment}
                                    </TableCell>
                                    <TableCell align="right">
                                      {customer}
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>
                    )}
                    {data.rests.length > 0 && (
                      <>
                        <Typography sx={{ padding: "0 10px" }} variant="h6">
                          {t("Nezaplacené a neztžené resty od kordinátora")}
                        </Typography>
                        <TableContainer
                          sx={{ maxHeight: "100%", marginBottom: "30px" }}
                        >
                          <Table size="small" aria-label="simple table">
                            <caption
                              style={{ padding: "5px 5px", fontWeight: "700" }}
                            >
                              {t("Celkem")}{" "}
                              {data.rests
                                .reduce(
                                  (acc, rest) => acc + parseFloat(rest.sum),
                                  0
                                )
                                .toFixed(2)}
                              - Kč
                            </caption>
                            <TableHead>
                              <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell align="right">{t("Rest")}</TableCell>
                                <TableCell align="right">
                                  {t("Částka")}
                                </TableCell>
                                <TableCell align="right">
                                  {t("Komentář")}
                                </TableCell>
                                <TableCell align="right">
                                  {t("Uživatel")}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data.rests.map(
                                ({ rest, comment, customer, sum }, index) => (
                                  <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                      {index + 1}
                                    </TableCell>
                                    <TableCell align="right">{rest}</TableCell>
                                    <TableCell align="right">{sum}</TableCell>
                                    <TableCell align="right">
                                      {comment}
                                    </TableCell>
                                    <TableCell align="right">
                                      {customer}
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>
                    )}
                    {data.medicalCheckups.length > 0 && (
                      <>
                        <Typography sx={{ padding: "0 10px" }} variant="h6">
                          {t("Lékařské prohlídky")}
                        </Typography>
                        <TableContainer
                          sx={{ maxHeight: "100%", marginBottom: "30px" }}
                        >
                          <Table size="small" aria-label="simple table">
                            <caption
                              style={{ padding: "5px 5px", fontWeight: "700" }}
                            >
                              {t("Celkem")}{" "}
                              {data.medicalCheckups
                                .reduce(
                                  (acc, rest) => acc + parseFloat(rest.price),
                                  0
                                )
                                .toFixed(2)}
                              - Kč
                            </caption>
                            <TableHead>
                              <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell align="right">{t("Typ")}</TableCell>
                                <TableCell align="right">
                                  {t("Částka")}
                                </TableCell>
                                <TableCell align="right">
                                  {t("Měsíc")}
                                </TableCell>
                                <TableCell align="right">{t("Rok")}</TableCell>
                                <TableCell align="right">
                                  {t("Zadal")}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data.medicalCheckups.map(
                                (
                                  { createdBy, month, year, price, type_name },
                                  index
                                ) => (
                                  <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                      {index + 1}
                                    </TableCell>
                                    <TableCell align="right">
                                      {type_name}
                                    </TableCell>
                                    <TableCell align="right">{price}</TableCell>
                                    <TableCell align="right">{month}</TableCell>
                                    <TableCell align="right">{year}</TableCell>
                                    <TableCell align="right">
                                      {createdBy}
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>
                    )}
                  </Paper>
                </Box>
              )}

              <Box
                sx={{
                  flex: "1 1 20%",
                  maxHeight: "100%",
                }}
              >
                <Paper
                  sx={{
                    // overflow: "auto",
                    // maxHeight: "100%",
                    marginBottom: "20px",
                  }}
                >
                  {(data.finesCoordinator.length > 0 ||
                    data.finesCustomer.length > 0) && (
                    <Typography
                      sx={{ backgroundColor: "#5866d2", color: "white" }}
                      align="center"
                      variant="h6"
                    >
                      {t("Pokuty")}
                    </Typography>
                  )}
                  {data.finesCoordinator.length > 0 && (
                    <>
                      <Typography sx={{ padding: "0 10px" }} variant="h6">
                        {t("Pokuty - Koordinator")}
                      </Typography>
                      <TableContainer
                        sx={{ maxHeight: "100%", marginBottom: "30px" }}
                      >
                        <Table size="small" aria-label="simple table">
                          <caption
                            style={{ padding: "5px 5px", fontWeight: "700" }}
                          >
                            {t("Celkem")}{" "}
                            {data.finesCoordinator
                              .reduce(
                                (acc, rest) => acc + parseFloat(rest.sum),
                                0
                              )
                              .toFixed(2)}
                            - Kč
                          </caption>
                          <TableHead>
                            <TableRow>
                              <TableCell>#</TableCell>
                              <TableCell align="right">{t("Pokuta")}</TableCell>
                              <TableCell align="right">{t("Částka")}</TableCell>
                              <TableCell align="right">{t("Datum")}</TableCell>
                              <TableCell align="right">
                                {t("Koordinátor")}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.finesCoordinator.map(
                              ({ employee, sum, date, fines }, index) => (
                                <TableRow key={index}>
                                  <TableCell component="th" scope="row">
                                    {index + 1}
                                  </TableCell>
                                  <TableCell align="right">{fines}</TableCell>
                                  <TableCell align="right">{sum}</TableCell>
                                  <TableCell align="right">{date}</TableCell>
                                  <TableCell align="right">
                                    {employee}
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  )}
                  {data.finesCustomer.length > 0 && (
                    <>
                      <Typography sx={{ padding: "0 10px" }} variant="h6">
                        {t("Pokuty - ubytovatel")}
                      </Typography>
                      <TableContainer
                        sx={{ maxHeight: "100%", marginBottom: "30px" }}
                      >
                        <Table size="small" aria-label="simple table">
                          <caption
                            style={{ padding: "5px 5px", fontWeight: "700" }}
                          >
                            {t("Celkem")}{" "}
                            {data.finesCustomer
                              .reduce(
                                (acc, rest) => acc + parseFloat(rest.sum),
                                0
                              )
                              .toFixed(2)}
                            - Kč
                          </caption>
                          <TableHead>
                            <TableRow>
                              <TableCell>#</TableCell>
                              <TableCell align="right">{t("Pokuta")}</TableCell>
                              <TableCell align="right">{t("Částka")}</TableCell>
                              <TableCell align="right">{t("Datum")}</TableCell>
                              <TableCell align="right">
                                {t("Ubytovatel")}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.finesCustomer.map(
                              ({ employee, sum, date, fines }, index) => (
                                <TableRow key={index}>
                                  <TableCell component="th" scope="row">
                                    {index + 1}
                                  </TableCell>
                                  <TableCell align="right">{fines}</TableCell>
                                  <TableCell align="right">{sum}</TableCell>
                                  <TableCell align="right">{date}</TableCell>
                                  <TableCell align="right">
                                    {employee}
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  )}
                  {data.restyUbytovatel.length > 0 && (
                    <>
                      <Typography sx={{ padding: "0 10px" }} variant="h6">
                        {t("Škody ubytovatel")}
                      </Typography>
                      <TableContainer
                        sx={{ maxHeight: "100%", marginBottom: "30px" }}
                      >
                        <Table size="small" aria-label="simple table">
                          <caption
                            style={{ padding: "5px 5px", fontWeight: "700" }}
                          >
                            {t("Celkem")}{" "}
                            {data.restyUbytovatel
                              .reduce(
                                (acc, rest) => acc + parseFloat(rest.suma),
                                0
                              )
                              .toFixed(2)}
                            - Kč
                          </caption>
                          <TableHead>
                            <TableRow>
                              <TableCell>#</TableCell>
                              <TableCell align="right">{t("Pokuta")}</TableCell>
                              <TableCell align="right">{t("Částka")}</TableCell>
                              <TableCell align="right">{t("Datum")}</TableCell>
                              <TableCell align="right">
                                {t("Ubytovna")}
                              </TableCell>
                              <TableCell align="right">{t("Pokoj")}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.finesCustomer.map(
                              (
                                { room, suma, datum, komentar, hostel },
                                index
                              ) => (
                                <TableRow key={index}>
                                  <TableCell component="th" scope="row">
                                    {index + 1}
                                  </TableCell>
                                  <TableCell align="right">
                                    {komentar}
                                  </TableCell>
                                  <TableCell align="right">{suma}</TableCell>
                                  <TableCell align="right">{datum}</TableCell>
                                  <TableCell align="right">{hostel}</TableCell>
                                  <TableCell align="right">{room}</TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  )}
                  {data.downpayments.length > 0 && (
                    <>
                      <Typography
                        sx={{ backgroundColor: "#5866d2", color: "white" }}
                        align="center"
                        variant="h6"
                      >
                        {t("Zálohy")}
                      </Typography>
                      <TableContainer
                        sx={{ maxHeight: "100%", marginBottom: "30px" }}
                      >
                        <Table size="small" aria-label="simple table">
                          <caption
                            style={{ padding: "5px 5px", fontWeight: "700" }}
                          >
                            {t("Celkem")}{" "}
                            {data.downpayments
                              .reduce(
                                (acc, rest) => acc + parseFloat(rest.sum),
                                0
                              )
                              .toFixed(2)}{" "}
                            - Kč
                          </caption>
                          <TableHead>
                            <TableRow>
                              <TableCell>#</TableCell>
                              <TableCell align="right">
                                {t("Schválil")}
                              </TableCell>
                              <TableCell align="right">{t("Datum")}</TableCell>
                              <TableCell align="right">{t("Částka")}</TableCell>
                              <TableCell align="right">
                                {t("Uživatel")}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.downpayments.map(
                              ({ employee, date, sum, name }, index) => (
                                <TableRow key={index}>
                                  <TableCell component="th" scope="row">
                                    {index + 1}
                                  </TableCell>
                                  <TableCell align="right">
                                    {employee}
                                  </TableCell>
                                  <TableCell align="right">{date}</TableCell>
                                  <TableCell align="right">{sum}</TableCell>
                                  <TableCell align="right">{name}</TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  )}
                  {data.reward.length > 0 && (
                    <>
                      <Typography
                        sx={{ backgroundColor: "#5866d2", color: "white" }}
                        align="center"
                        variant="h6"
                      >
                        {t("Odměny dodavatelům")}
                      </Typography>
                      <TableContainer
                        sx={{ maxHeight: "100%", marginBottom: "30px" }}
                      >
                        <Table size="small" aria-label="simple table">
                          <caption
                            style={{ padding: "5px 5px", fontWeight: "700" }}
                          >
                            {t("Celkem")}{" "}
                            {data.reward
                              .reduce(
                                (acc, rest) => acc + parseFloat(rest.sum),
                                0
                              )
                              .toFixed(2)}{" "}
                            - Kč
                          </caption>
                          <TableHead>
                            <TableRow>
                              <TableCell>#</TableCell>
                              <TableCell align="right">{t("Částka")}</TableCell>
                              <TableCell align="right">
                                {t("Datum platby")}
                              </TableCell>
                              <TableCell align="right">
                                {t("Za koho")}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.reward.map(
                              ({ employee, date, sum }, index) => (
                                <TableRow key={index}>
                                  <TableCell component="th" scope="row">
                                    {index + 1}
                                  </TableCell>
                                  <TableCell align="right">{sum}</TableCell>
                                  <TableCell align="right">{date}</TableCell>
                                  <TableCell align="right">
                                    {employee}
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  )}
                  {data.commission.length > 0 && (
                    <>
                      <Typography
                        sx={{ backgroundColor: "#5866d2", color: "white" }}
                        align="center"
                        variant="h6"
                      >
                        {t("Provize")}
                      </Typography>
                      <TableContainer
                        sx={{ maxHeight: "100%", marginBottom: "30px" }}
                      >
                        <Table size="small" aria-label="simple table">
                          <caption
                            style={{ padding: "5px 5px", fontWeight: "700" }}
                          >
                            {t("Celkem")}{" "}
                            {data.commission
                              .reduce(
                                (acc, comm) => acc + parseFloat(comm.total),
                                0
                              )
                              .toFixed(2)}{" "}
                            - Kč
                          </caption>
                          <TableHead>
                            <TableRow>
                              <TableCell>#</TableCell>
                              <TableCell align="right">
                                {t("Uživatel")}
                              </TableCell>
                              <TableCell align="right">{t("Role")}</TableCell>
                              <TableCell align="right">{t("Plán")}</TableCell>
                              <TableCell align="right">
                                {t("Chybi hod.")}
                              </TableCell>
                              <TableCell align="right">
                                {t("Hodiny koordinatora")}
                              </TableCell>
                              <TableCell align="right">
                                {t("Plán bez N")}
                              </TableCell>
                              <TableCell align="right">
                                {t("Počet dnů")}
                              </TableCell>
                              <TableCell align="right">
                                {t("Provoz hodin")}
                              </TableCell>
                              <TableCell align="right">{t("N")}</TableCell>
                              <TableCell align="right">{t("NV")}</TableCell>
                              <TableCell align="right">{t("C9")}</TableCell>
                              <TableCell align="right">{t("A")}</TableCell>
                              <TableCell align="right">{t("D")}</TableCell>
                              <TableCell align="right">{t("CD")}</TableCell>
                              <TableCell align="right">{t("O")}</TableCell>
                              <TableCell align="right">
                                {t("Suma hod. nemoci")}
                              </TableCell>
                              <TableCell align="right">
                                {t("Hod.Už. bez nemocných")}
                              </TableCell>
                              <TableCell align="right">
                                {t("Provize/hod.")}
                              </TableCell>
                              <TableCell align="right">
                                {t("Provize celkem")}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.commission.map(
                              (
                                {
                                  customer_label,
                                  role_label,
                                  plan,
                                  customer_hours,
                                  day_count,
                                  commission_hours,
                                  missing_hours,
                                  coordinator_hours,
                                  work_hours,
                                  plan_without_o,
                                  n,
                                  nv,
                                  c9,
                                  a,
                                  d,
                                  cd,
                                  o,
                                  n_hours,
                                  total,
                                },
                                index
                              ) => (
                                <TableRow key={index}>
                                  <TableCell component="th" scope="row">
                                    {index + 1}
                                  </TableCell>
                                  <TableCell align="right">
                                    {customer_label}
                                  </TableCell>
                                  <TableCell align="right">
                                    {role_label}
                                  </TableCell>
                                  <TableCell align="right">{plan}</TableCell>
                                  <TableCell align="right">
                                    {missing_hours}
                                  </TableCell>
                                  <TableCell align="right">
                                    {coordinator_hours}
                                  </TableCell>
                                  <TableCell align="right">
                                    {plan_without_o}
                                  </TableCell>
                                  <TableCell align="right">
                                    {day_count}
                                  </TableCell>
                                  <TableCell align="right">
                                    {work_hours}
                                  </TableCell>
                                  <TableCell align="right">{n}</TableCell>
                                  <TableCell align="right">{nv}</TableCell>
                                  <TableCell align="right">{c9}</TableCell>
                                  <TableCell align="right">{a}</TableCell>
                                  <TableCell align="right">{d}</TableCell>
                                  <TableCell align="right">{cd}</TableCell>
                                  <TableCell align="right">{o}</TableCell>
                                  <TableCell align="right">{n_hours}</TableCell>
                                  <TableCell align="right">
                                    {customer_hours}
                                  </TableCell>
                                  <TableCell align="right">
                                    {commission_hours}
                                  </TableCell>
                                  <TableCell align="right">{total}</TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  )}
                  {policy_actions.includes("documents") && (
                    <>
                      <FormControl sx={{ m: 1 }}>
                        <Select
                          size="small"
                          value={selectedValue}
                          onChange={handleSelectChange}
                        >
                          <MenuItem key="all" value="all">
                            {`${t("Nahrát")} pásku`}
                          </MenuItem>
                          {data.allVisits.map((item, index) => (
                            <MenuItem key={index} value={item.customer_id}>
                              {item.customer}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <input
                        type="file"
                        style={{ display: "none" }}
                        ref={fileInputRef}
                        onChange={handleFileChange}
                      />
                    </>
                  )}
                  {attachments.length > 0 && (
                    <Box sx={{ m: 1 }}>
                      {attachments.map((attachment) => (
                        <Box
                          sx={{
                            display: "flex",
                            marginBottom: "5px",
                            gap: "5px",
                          }}
                        >
                          <Chip
                            key={attachment.id}
                            color="primary"
                            sx={{ width: "150px" }}
                            label={attachment.name}
                            variant="outlined"
                            onClick={() =>
                              downloadAttachment(
                                attachment.path,
                                attachment.id,
                                attachment.name
                              )
                            }
                            onDelete={() => {
                              policy_actions.includes("documents")
                                ? deleteAtt(attachment.id)
                                : alert(t("Nemáte právo smazat soubory"));
                            }}
                          />
                          <Box>
                            <IconButton
                              sx={{ mt: -1 }}
                              color="primary"
                              onClick={() => {
                                setAttachmentId(attachment.id);
                                handleOpenEmail();
                              }}
                            >
                              <EmailIcon />
                            </IconButton>
                          </Box>
                          <Box display="flow">
                            {attachment.log.map((log) => (
                              <Box>
                                {log.date}, {log.name}
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  )}
                </Paper>
              </Box>
            </Box>
            <Typography variant="h5" align="center">
              {t("Celkem srážky ze mzdy")} {data.totalSum.toFixed(2)},- Kč
            </Typography>
          </>
        )}
      </Container>
    </div>
  );
};
export default IndividualSalaryReport;
