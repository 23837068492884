import UrlService from "./UrlService";
import HttpService from "./HttpService";

class SessionStorageService {
  static remove(name) {
    const rx = new RegExp(name);
    var n = localStorage.length;
    while (n--) {
      var key = localStorage.key(n);
      if (rx.test(key)) {
        localStorage.removeItem(key);
      }
    }
  }

  static update(type, data) {
    localStorage.removeItem(type + "_" + data.id);
    localStorage.setItem(type + "_" + data.id, JSON.stringify(data));
    var arr = [];
    JSON.parse(localStorage.getItem(type)).map((e) => {
      if (e.id == data.id) {
        arr.push(data);
      } else {
        arr.push(e);
      }
    });

    localStorage.removeItem(type);
    localStorage.setItem(type, JSON.stringify(arr));
  }

  static add(type, data) {
    localStorage.setItem(type + "_" + data.id, JSON.stringify(data));
    var arr = JSON.parse(localStorage.getItem(type));
    arr.push(data);
    localStorage.removeItem(type);
    localStorage.setItem(type, JSON.stringify(arr));
  }

  static remove_by_id(type, id) {
    localStorage.removeItem(type + "_" + id);
    var arr = [];
    JSON.parse(localStorage.getItem(type)).map((e) => {
      if (e.id != id) {
        arr.push(e);
      }
    });

    localStorage.removeItem(type);
    localStorage.setItem(type, JSON.stringify(arr));
  }

  static columns_permissions() {
    localStorage.removeItem("columns_permissions");
    HttpService.get(UrlService.apiDomain() + "api/columns_permissions")
      .then((res) => {
        localStorage.setItem("columns_permissions", JSON.stringify(res.data));
      })
      .catch((error) => {
        console.log(error);
      });
  }

  static booleans() {
    localStorage.setItem(
      "booleans",
      JSON.stringify([
        { name_en: "No", name_cs: "Ne", name: "Ne", id: 0 },
        { name_en: "Yes", name: "Ano", name_cs: "Ano", id: 1 },
      ])
    );
    localStorage.setItem(
      "booleans_active",
      JSON.stringify([
        { name_en: "No", name_cs: "Ne", name: "Ne", id: 0 },
        { name_en: "Yes", name_cs: "Ano", name: "Ano", id: 1 },
      ])
    );

    localStorage.setItem(
      "booleans_0",
      JSON.stringify({ name_en: "No", name_cs: "Ne", name: "Ne", id: 0 })
    );
    localStorage.setItem(
      "booleans_1",
      JSON.stringify({ name_en: "Yes", name_cs: "Ano", name: "Ano", id: 1 })
    );
  }

  static initializeLocalDB(data, is_public) {
    var public_settings_version = 1;
    var private_settings_version = 1;

    if (localStorage.getItem("private_settings_version") != null)
      private_settings_version = localStorage.getItem(
        "private_settings_version"
      );
    if (localStorage.getItem("public_settings_version") != null)
      public_settings_version = localStorage.getItem("public_settings_version");

    if (!is_public) {
      localStorage.setItem("my_user", JSON.stringify(data.my_user));
      // localStorage.setItem("my_users_meta", JSON.stringify(data.my_users_meta));
      // localStorage.setItem("my_roles_meta", JSON.stringify(data.my_roles_meta));
      // localStorage.setItem(
      //   "my_permissions",
      //   JSON.stringify(data.my_permissions)
      // );
    }

    if (
      (is_public && public_settings_version != data.settings_version.value) ||
      (!is_public && private_settings_version != data.settings_version.value)
    ) {
      SessionStorageService.booleans();

      for (const [key, value] of Object.entries(data)) {
        if (key == "settings_version") {
          // Set settings versions
          if (is_public) {
            localStorage.setItem("public_settings_version", value.value);
          } else {
            localStorage.setItem("private_settings_version", value.value);
          }
        } else {
          if (
            key !== "all_customers" &&
            key !== "supplier_companies" &&
            key !== "rooms" &&
            key !== "resty_types" &&
            key !== "customer_interaction_results"
          )
            localStorage.setItem(key, JSON.stringify(value));
          //   if (Array.isArray(value) && key != "translates") {
          //     value.map((e) => {
          //       localStorage.setItem(key + "_" + e.id, JSON.stringify(e));
          //     });
          //   }
        }
      }
    }
  }
}

export default SessionStorageService;
